/* SessionComplete.css */
.session-complete-wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7F7F7;
  padding: 24px;
  z-index: 1000;
}

/* Modern confetti animation */
.confetti-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.6;
  background-image: 
    radial-gradient(circle at 25% 25%, rgba(127, 90, 240, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 75% 75%, rgba(255, 145, 77, 0.1) 0%, transparent 50%);
}

.session-content {
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: fade-in 0.5s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.session-title {
  font-size: 28px;
  font-weight: 500;
  color: #58585f;
  margin: 0 0 50px 0;
  letter-spacing: -0.5px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  max-width: 300px; /* Match the button width */
  margin: 0 auto 60px;
}

.metric-card {
  background: white;
  border-radius: 16px;
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  min-height: 160px;
}

.metric-header {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.metric-icon {
  color: #7F5AF0;
  font-size: 24px;
}

.metric-value {
  font-size: 38px;
  color: #58585f;
  font-weight: 600;
  line-height: 1.2;
  align-self: center;
  margin-top: auto;
}

.metric-label {
  font-size: 15px;
  color: #58585f;
  font-weight: 500;
}

.view-results-button {
  background: #7F5AF0;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 18px 36px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 6px rgba(127, 90, 240, 0.2);
}

.view-results-button:hover {
  background: #6a4cc7;
}

@media (max-width: 768px) {
  .session-complete-wrapper {
    padding: 20px;
  }

  .session-title {
    font-size: 24px;
    margin-bottom: 40px;
  }

  .metrics-grid {
    gap: 20px;
    margin-bottom: 50px;
  }

  .metric-card {
    padding: 24px 16px;
    min-height: 140px;
  }

  .metric-value {
    font-size: 32px;
  }
  
  .view-results-button {
    max-width: 100%;
    padding: 16px;
  }
}

@media (max-width: 480px) {
  .metrics-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  
  .session-title {
    font-size: 22px;
    margin-bottom: 30px;
  }
  
  .metric-card {
    padding: 20px;
    min-height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
  
  .metric-header {
    width: auto;
  }
  
  .metric-value {
    font-size: 32px;
    margin-top: 0;
  }
}