.exams-list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.exam-item {
    width: 100%;
    max-width: 600px;
}

.exam-item-box {
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Hide overflow content */
}

.exam-item-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.exam-item-completed {
    background: #5439ff42;
    color: black;
}

.exam-item-not-completed {
    background: white;
    color: black;
}

.exam-item-locked {
    color: #000;
    background: white;
    cursor: not-allowed;
}

.exam-item-checkmark-container {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #5539FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.exam-item-checkmark {
    width: 15px;
    height: 15px;
    background-image: url('../assets/icons/checkmark.png');
    background-size: contain;
    background-repeat: no-repeat;
    filter: invert(100%);
}

.exam-item-info {
    align-self: flex-end;
    width: 100%;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 100%; /* Ensure it doesn't exceed the box height */
}

.exam-title-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #828282;
    font-size: clamp(11px, 2vw, 16px);
    margin-bottom: 5px;
}

.exam-subtitle-text {
    font-weight: 600;
    color: #1C1C1C;
    line-height: 1.2em;
    font-size: clamp(13px, 3vw, 18px);
    word-break: break-word;
}

@media (max-width: 768px) {
    .exams-list-container {
        grid-template-columns: 1fr;
        padding: 0 15px;
    }

    .exam-item-box {
        height: 100px;
    }
}

@media (max-width: 480px) {
    .exam-item-box {
        height: 90px;
    }
}