/* global-style-override.css */
/* This file overrides specific styles in the main app to match new front */

/* ===============================================
   GLOBAL LAYOUT AND SPACING
   =============================================== */
.App .main-content {
  padding: 0; /* Remove default padding */
}

/* All view containers */
.dashboard,
.training-view,
.settings-view,
.study-plan-view {
  padding: var(--padding-desktop);
  max-width: 1200px;
  margin: 0 auto;
}

/* Header Styles */
.dashboard-header,
.training-header,
.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px; /* Consistent with new front */
  width: 100%;
}

.dashboard-header h1,
.training-header h1,
.settings-header h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

/* Page Containers */
.dashboard-content,
.training-content,
.settings-content {
  margin-bottom: 32px;
}

/* ===============================================
   CARD STYLES
   =============================================== */
.card {
  border-radius: var(--border-radius-lg);
  background: var(--white);
  border: 1px solid var(--border-color);
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.card-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--border-color);
}

.card-content {
  padding: 20px;
}

/* ===============================================
   BUTTONS
   =============================================== */
.primary-button {
  background: var(--primary-purple);
  color: var(--white);
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.primary-button:hover {
  background: var(--primary-purple-dark);
}

.secondary-button {
  background: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.secondary-button:hover {
  border-color: var(--primary-purple);
  color: var(--primary-purple);
}

/* ===============================================
   GRID LAYOUTS
   =============================================== */
.grid-2-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.grid-3-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

/* ===============================================
   MOBILE STYLES
   =============================================== */
@media (max-width: 768px) {
  .dashboard,
  .training-view,
  .settings-view,
  .study-plan-view {
    padding: var(--padding-mobile);
  }
  
  .dashboard-header h1,
  .training-header h1,
  .settings-header h1 {
    font-size: 20px;
  }
  
  .dashboard-header,
  .training-header,
  .settings-header {
    margin-bottom: 24px;
  }
  
  .grid-2-columns,
  .grid-3-columns {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  
  .card-header {
    padding: 12px 16px;
  }
  
  .card-content {
    padding: 16px;
  }
}

/* Extra Small Screens */
@media (max-width: 375px) {
  .dashboard,
  .training-view,
  .settings-view,
  .study-plan-view {
    padding: var(--padding-xsmall);
  }
  
  .dashboard-header,
  .training-header,
  .settings-header {
    margin-bottom: 20px;
  }
  
  .primary-button,
  .secondary-button {
    padding: 8px 12px;
    font-size: 13px;
  }
}

/* ===============================================
   FEEDBACK ELEMENTS
   =============================================== */
.toast-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-purple);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1000;
  animation: slideUp 0.3s ease, fadeOut 0.3s ease 2.7s;
  max-width: 90%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

/* Define animations to match new front */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* ===============================================
   FORM STYLES
   =============================================== */
.form-group {
  margin-bottom: 16px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--text-primary);
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: var(--primary-purple);
}

/* ===============================================
   STATS COMPONENT OVERRIDE
   =============================================== */
.stats {
  display: flex;
  gap: 16px;
  align-items: center;
}

.level {
  background: rgba(127, 90, 240, 0.08);
  color: var(--primary-purple);
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  border: 1px solid rgba(127, 90, 240, 0.15);
}

.streak, 
.xp {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
}

/* Mobile Stats */
@media (max-width: 768px) {
  .stats {
    font-size: 14px;
    gap: 12px;
  }
  
  .level {
    font-size: 13px;
    padding: 3px 6px;
  }
}