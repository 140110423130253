/* Settings.css */
:root {
  --primary-purple: #7F5AF0;
  --primary-purple-dark: #6a4cc7;
  --light-purple: rgba(127, 90, 240, 0.15);
  --lightest-purple: rgba(127, 90, 240, 0.08);
  --text-primary: #1f2937;
  --text-secondary: #6b7280;
  --border-color: #e5e7eb;
  --white: #fff;
  --border-radius-lg: 12px;
  --border-radius-md: 8px;
  --border-radius-pill: 24px;
}

.settings {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px 32px;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
}

.settings-header h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  line-height: 1.2;
}

.settings-subtitle {
  font-size: 14px;
  color: var(--text-secondary);
  margin-top: 4px;
  display: block;
}

.settings-section {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.settings-section h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border-color);
}

/* Account section styles */
.settings-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.account-info {
  display: flex;
  align-items: center;
}

.profile-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--primary-purple);
  color: white;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.account-details h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.account-details p {
  font-size: 14px;
  color: var(--text-secondary);
}

.edit-profile-btn {
  background-color: transparent;
  border: 1px solid var(--primary-purple);
  color: var(--primary-purple);
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: var(--border-radius-pill);
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-profile-btn:hover {
  background-color: var(--lightest-purple);
}

/* Subscription card styles */
.subscription-card {
  background-color: var(--lightest-purple);
  border-radius: var(--border-radius-lg);
  padding: 20px;
  margin-top: 16px;
}

.subscription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.subscription-header h3 {
  font-size: 16px;
  font-weight: 600;
}

.subscription-badge {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: var(--border-radius-pill);
}

.subscription-badge.premium {
  background-color: var(--primary-purple);
  color: white;
}

.subscription-badge.free {
  background-color: var(--light-purple);
  color: var(--primary-purple-dark);
}

.subscription-card p {
  font-size: 14px;
  margin-bottom: 16px;
  color: var(--text-secondary);
}

.upgrade-btn {
  background-color: var(--primary-purple);
  color: white;
  border: none;
  border-radius: var(--border-radius-pill);
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
}

.upgrade-btn:hover {
  background-color: var(--primary-purple-dark);
}

.upgrade-btn .icon {
  margin-right: 8px;
}

/* Settings option styles */
.settings-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--border-color);
}

.settings-option:last-child {
  border-bottom: none;
}

.option-info {
  display: flex;
  align-items: center;
}

.option-icon {
  width: 40px;
  height: 40px;
  background-color: var(--lightest-purple);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: var(--primary-purple);
  flex-shrink: 0; /* Prevent icon from shrinking on mobile */
}

.option-text h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.option-text p {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
}

/* Toggle switch styles */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.toggle-switch label:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.toggle-switch input:checked + label {
  background-color: var(--primary-purple);
}

.toggle-switch input:checked + label:before {
  transform: translateX(24px);
}

/* Text button styles */
.text-button {
  background: none;
  border: none;
  color: var(--primary-purple);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: var(--border-radius-pill);
  transition: background-color 0.2s ease;
}

.text-button:hover {
  background-color: var(--lightest-purple);
}

/* Edit profile form styles */
.edit-profile-form {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 20px;
  margin: 15px 0;
}

.form-row {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.form-group {
  flex: 1;
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--text-secondary);
}

.form-group input, .disabled-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 14px;
}

.disabled-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.input-note {
  display: block;
  font-size: 12px;
  color: var(--text-secondary);
  margin-top: 4px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.cancel-btn {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: var(--border-radius-pill);
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-btn:hover {
  background-color: #f5f5f5;
}

.save-btn {
  background-color: var(--primary-purple);
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: var(--border-radius-pill);
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-btn:hover {
  background-color: var(--primary-purple-dark);
}

/* Stats grid styles */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 16px;
}

.stat-card {
  background-color: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 12px;
}

.stat-icon {
  width: 40px;
  height: 40px;
  background-color: var(--lightest-purple);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-purple);
}

.emoji-icon {
  font-size: 20px;
  line-height: 1;
}

.stat-info {
  display: flex;
  flex-direction: column;
}

.stat-value {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
}

.stat-label {
  font-size: 14px;
  color: var(--text-secondary);
}

/* JLPT settings styles */
.jlpt-settings {
  margin-top: 8px;
}

.select-wrapper {
  position: relative;
}

.select-wrapper select {
  appearance: none;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-pill);
  padding: 8px 12px;
  padding-right: 36px;
  font-size: 14px;
  color: var(--text-primary);
  cursor: pointer;
  width: 180px;
}

.select-wrapper::after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--text-secondary) transparent transparent transparent;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Danger zone styles */
.danger-zone h2 {
  color: #dc2626;
}

.option-icon.danger {
  background-color: rgba(220, 38, 38, 0.1);
  color: #dc2626;
}

.delete-account-btn {
  background-color: transparent;
  border: 1px solid #dc2626;
  color: #dc2626;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: var(--border-radius-pill);
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-account-btn:hover {
  background-color: #dc2626;
  color: white;
}

/* Privacy links styles */
.privacy-links {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

.privacy-links a {
  color: var(--primary-purple);
  font-size: 14px;
  text-decoration: none;
  transition: color 0.2s ease;
}

.privacy-links a:hover {
  color: var(--primary-purple-dark);
  text-decoration: underline;
}

/* App info styles */
.app-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid var(--border-color);
}

.app-logo {
  display: flex;
  align-items: center;
}

.app-logo img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.app-logo span {
  font-size: 18px;
  font-weight: 600;
}

.app-version {
  font-size: 14px;
  color: var(--text-secondary);
}

/* Loading state */
.settings-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary-purple);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .settings {
    padding: 16px;
  }
  
  .settings-header {
    padding-top: 8px;
  }
  
  .settings-header h1 {
    font-size: 20px;
  }
  
  .settings-section {
    padding: 16px;
  }
  
  .settings-card {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
  
  .account-info {
    flex: 1;
  }
  
  .edit-profile-btn {
    align-self: center;
    margin-left: auto;
  }
  
  .subscription-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .subscription-header .subscription-badge {
    margin-left: auto;
  }
  
  .form-row {
    flex-direction: column;
    gap: 0;
  }
  
  /* Reduce space between email field and the form row above in mobile */
  .edit-profile-form .form-row + .form-group {
    margin-top: 0;
  }
  
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
  .settings-option {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: nowrap; /* Prevent wrapping on mobile */
  }

  .settings-option .option-info {
    flex: 1;
  }
  
  .toggle-switch,
  .text-button,
  .delete-account-btn {
    align-self: center;
    margin-left: auto;
    flex-shrink: 0; /* Prevent buttons from shrinking on mobile */
    min-width: max-content; /* Ensure buttons don't shrink below their content */
  }
  
  .select-wrapper {
    align-self: center;
    min-width: 120px; /* Reduced from 180px to fit better on mobile */
    flex-shrink: 0; /* Prevent from shrinking */
  }
  
  .select-wrapper select {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .jlpt-settings .settings-option {
    margin-bottom: 16px;
  }
  
  .privacy-links {
    flex-direction: column;
    gap: 12px;
  }
  
  .app-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .upgrade-btn {
    width: 100%;
    justify-content: center;
  }
}

/* Extra Small Screens */
@media (max-width: 375px) {
  .settings {
    padding: 12px;
  }
}

/* Static level display - replaces dropdown */
.static-level {
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-pill);
  padding: 8px 12px;
  padding-right: 12px; /* Same padding on both sides - no arrow space needed */
  font-size: 14px;
  color: var(--text-primary);
  width: 180px;
  font-weight: normal; /* Same as regular select */
}

/* Remove arrow for the select wrapper */
.select-wrapper.no-arrow::after {
  display: none;
}