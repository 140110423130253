/* ===============================================
   BASE COMMON STYLES
   =============================================== */
   .qd-question-display {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #111827;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
  }
  
  .qd-question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
  }
  
  .qd-question-title {
    font-size: 20px;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }
  
  .qd-bookmark-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: #111827;
    font-size: 24px;
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .qd-general-instruction {
    font-size: 16px;
    line-height: 1.6;
    color: #374151;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #D1D5DB;
  }
  
  .qd-question-text {
    font-size: 16px;
    line-height: 1.6;
    color: #111827;
    margin-bottom: 24px;
    white-space: pre-line;
    overflow-wrap: break-word;
    word-break: normal;
  }
  
  /* ===============================================
     ANSWER OPTIONS STYLES - CONSISTENT ACROSS ALL VIEWS
     =============================================== */
  .qd-answer-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
  }
  
  .qd-answer-option {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 16px;
    border: 1px solid #E5E7EB;
    border-radius: 12px;
    background: #FFFFFF;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
    box-sizing: border-box;
  }
  
  .qd-answer-number {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E5E7EB;
    border-radius: 50%;
    font-size: 14px;
    color: #6B7280;
    background: #F9FAFB;
    flex-shrink: 0;
  }
  
  .qd-answer-text {
    font-size: 15px;
    line-height: 1.5;
    color: #111827;
  }
  
  /* Answer states */
  .qd-answer-option:hover:not(:disabled) {
    border-color: #9333EA;
    background: #F5F3FF;
  }
  
  .qd-answer-option.selected {
    border-color: #9333EA;
    background: #F5F3FF;
  }
  
  .qd-answer-option.selected .qd-answer-number {
    background: #9333EA;
    color: #FFFFFF;
    border-color: #9333EA;
  }
  
  .qd-answer-option.correct {
    border-color: #10B981;
    background: #ECFDF5;
  }
  
  .qd-answer-option.correct .qd-answer-number {
    background: #10B981;
    color: #FFFFFF;
    border-color: #10B981;
  }
  
  .qd-answer-option.incorrect {
    border-color: #EF4444;
    background: #FEF2F2;
  }
  
  .qd-answer-option.incorrect .qd-answer-number {
    background: #EF4444;
    color: #FFFFFF;
    border-color: #EF4444;
  }
  
  /* Disabled state */
  .qd-answer-option:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Explanation */
  .qd-explanation-section {
    margin-top: 24px;
    padding: 20px;
    background: #F9FAFB;
    border-radius: 12px;
    border: 1px solid #E5E7EB;
  }
  
  .qd-explanation-title {
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    margin-bottom: 12px;
  }
  
  .qd-explanation-text {
    font-size: 15px;
    line-height: 1.6;
    color: #374151;
  }
  
  /* ===============================================
     MULTIPLE QUESTION SPECIFIC STYLES
     =============================================== */
  .qd-multiple {
    padding: 0; /* Remove overall padding for multiple questions */
  }
  
  .qd-multiple .qd-container {
    height: calc(100vh - 140px); /* Fixed height for scrolling to work */
    position: relative;
    overflow: hidden;
  }
  
  .qd-multiple .qd-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    height: 100%;
    overflow: hidden;
  }
  
  /* Left reading section */
  .qd-reading-section {
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #E5E7EB;
    overflow: hidden;
  }
  
  .qd-text-scrollable {
    flex: 1;
    overflow-y: auto;
    padding-right: 8px;
    margin-top: 16px;
  }
  
  /* Right questions section */
  .qd-questions-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
  }
  
  .qd-questions-scroll {
    flex: 1;
    overflow-y: auto;
    padding-right: 8px;
  }
  
  /* Individual questions */
  .qd-question-block {
    margin-bottom: 32px;
  }
  
  .qd-question-block:last-child {
    margin-bottom: 0;
  }
  
  .qd-question-number {
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    margin-bottom: 8px;
  }
  
  /* Custom scrollbars */
  .qd-text-scrollable::-webkit-scrollbar,
  .qd-questions-scroll::-webkit-scrollbar {
    width: 4px;
  }
  
  .qd-text-scrollable::-webkit-scrollbar-track,
  .qd-questions-scroll::-webkit-scrollbar-track {
    background: #F3F4F6;
    border-radius: 2px;
  }
  
  .qd-text-scrollable::-webkit-scrollbar-thumb,
  .qd-questions-scroll::-webkit-scrollbar-thumb {
    background: #D1D5DB;
    border-radius: 2px;
  }
  
  /* Image container and styling */
  .qd-image-container {
    margin: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .qd-question-image {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: auto;
  }
  
  /* ===============================================
     MOBILE STYLES - KEEPING DESKTOP LAYOUT STRUCTURE
     =============================================== */
  @media (max-width: 768px) {
    .qd-question-display {
      padding: 12px; /* Consistent padding for mobile */
    }
  
    .qd-question-title {
      font-size: 18px;
    }
    
    .qd-general-instruction,
    .qd-question-text {
      font-size: 15px;
      margin-bottom: 16px;
    }
    
    /* Keep same size for answer choices across all views */
    .qd-answer-option {
      padding: 16px;
    }
    
    .qd-answer-number {
      width: 32px;
      height: 32px;
    }
    
    .qd-explanation-section {
      padding: 16px;
      margin-top: 16px;
    }
  
    /* Multiple questions mobile specific - preserving desktop-like scrolling */
    .qd-multiple {
      padding: 0;
    }
    
    .qd-multiple .qd-container {
      height: calc(100vh - 140px); /* Fixed height for proper scrolling */
    }
  
    .qd-multiple .qd-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }
    
    .qd-reading-section {
      height: 50%; /* Take 50% of available height */
      max-height: none; /* Remove max-height constraint */
      border-right: none;
      border-bottom: 1px solid var(--border-color);
      padding: 12px;
      overflow: hidden; /* Hide overflow for proper layout */
      display: flex;
      flex-direction: column;
    }
    
    .qd-questions-section {
      height: 50%; /* Take 50% of available height */
      padding: 12px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
    
    .qd-text-scrollable {
      flex: 1;
      overflow-y: auto;
      margin-top: 16px;
    }
    
    .qd-questions-scroll {
      flex: 1;
      overflow-y: auto;
    }
    
    /* Keep all borders, paddings, and margins consistent */
    .qd-multiple .qd-question-header,
    .qd-reading-section .qd-question-header,
    .qd-questions-section .qd-question-header {
      padding: 0;
      margin-bottom: 16px;
    }
    
    .qd-multiple .qd-general-instruction {
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
  
    .qd-image-container {
      margin: 12px 0;
    }
    
    .qd-question-image {
      border-radius: 6px;
    }
  }
  
  /* Very small screens */
  @media (max-width: 375px) {
    .qd-question-display {
      padding: 10px;
    }
    
    .qd-reading-section,
    .qd-questions-section {
      padding: 10px;
    }
    
    /* Keep answer options at consistent size */
    .qd-answer-option {
      padding: 16px;
    }
    
    .qd-answer-number {
      width: 32px;
      height: 32px;
    }
  }