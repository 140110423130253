/* Reset strict */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --sidebar-width: 240px;
  --primary-purple: #3E00EF;
  --dark-purple: #3200cc;
  --light-purple: rgba(127, 90, 240, 0.08);
  --lightest-purple: #f0ebff;
  --text-primary: #111827;
  --text-secondary: #6B7280;
  --background: #F9FAFB;
  --white: #FFFFFF;
  --border-color: #E5E7EB;
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --primary-purple-dark: #6a4cc7;
}

html, body, #root {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  font-family: 'Inter', sans-serif;
  color: var(--text-primary);
  background-color: var(--white);
}

/* Base Layout */
.App {
  display: flex;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.main-content {
  margin-left: var(--sidebar-width);
  flex: 1;
  width: calc(100% - var(--sidebar-width));
  position: relative;
  background-color: var(--white);
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Reset mobile layout */
  .App {
    display: block;
    width: 100%;
    overflow-x: hidden;
    padding-top: 48px; /* Add space for the mobile menu at top */
  }

  .main-content {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    position: relative;
    left: 0;
    right: 0;
  }

  /* Force all content to stay within boundaries */
  .dashboard {
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
    margin: 0 !important;
    padding: 16px !important;
  }

  /* Specific containers */
  .dashboard-header,
  .training-card,
  .contests-section,
  .resources-grid {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }
}

/* Extra Small Screens */
@media (max-width: 375px) {
  .main-content {
    padding: 0;
  }

  .dashboard {
    padding: 12px !important;
  }
}

/* Ensure nothing overflows */
.main-content > * {
  max-width: 100%;
}

/* Mobile menu icon */
.mobile-menu-icon {
  position: fixed;
  top: 16px;
  left: 16px;
  font-size: 24px;
  cursor: pointer;
  z-index: 101;
  color: #333;
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-icon {
    display: block;
  }
  
  .main-content {
    margin-left: 0;
  }
}

/* Dashboard header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;
}

.dashboard-header h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

/* Upgrade button styling (preserved from original) */
.upgrade-button {
  margin-right: 10px;
  display: inline-block;
  background-color: #3E00EF;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upgrade-button:hover {
  background-color: #3E00EF;
  transform: translateY(-2px);
}

.upgrade-button:active {
  background-color: #3E00EF;
  transform: translateY(0);
}