/* ===============================================
   Base Container
   =============================================== */
   .question-layout {
    position: fixed;
    inset: 0;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
  
  /* ===============================================
     Header Section
     =============================================== */
  .question-header {
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E5E7EB;
    background: #FFFFFF;
    position: relative;
  }
  
  .exit-btn {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    cursor: pointer !important;
    color: #111827;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
    z-index: 1002 !important;
    pointer-events: auto !important;
  }
  
  .timer-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 4px;
    color: #6B7280;
  }
  
  .timer-container span {
    font-size: 16px;
    font-weight: 500;
    font-variant-numeric: tabular-nums;
  }
  
  .timer-icon {
    color: #6B7280;
  }
  
  .questions-remaining {
    font-size: 13px;
    color: #6B7280;
    text-align: right;
    min-width: 120px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  /* ===============================================
     Content Area
     =============================================== */
  .question-container {
    flex: 1;
    overflow-y: auto;
    padding: 24px 16px;
    width: 100%;
    padding-bottom: 120px;
    background: #FFFFFF;
  }
  
  .question-content {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
  
  /* ===============================================
   Footer Section
   =============================================== */
.question-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: #FFFFFF;
  border-top: 1px solid #E5E7EB;
  display: grid;
  grid-template-columns: minmax(120px, auto) 1fr minmax(240px, auto);
  align-items: center;
  padding: 0 24px;
  gap: 16px;
  z-index: 1001;
}

/* Report Button */
.report-btn {
  grid-column: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #EF4444;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  width: fit-content;
}

/* Ask Mina Button */
.ask-mina-btn {
  grid-column: 2;
  justify-self: center;
  padding: 8px 24px;
  background: #9333EA;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Navigation Buttons */
.navigation-buttons {
  grid-column: 3;
  justify-self: end;
  display: flex;
  gap: 8px;
  width: fit-content;
}

.nav-btn {
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn.previous {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  color: #6B7280;
}

.nav-btn.next {
  background: #9333EA;
  color: #FFFFFF;
  border: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .question-header {
    height: 48px; /* Slightly smaller on mobile */
  }
  
  .question-container {
    padding: 16px 12px;
    padding-bottom: 100px;
  }
  
  .question-footer {
    height: 64px;
    padding: 0 16px;
    grid-template-columns: auto 1fr auto;
    gap: 12px;
  }

  .report-btn {
    padding: 8px;
  }

  .report-btn .report-text {
    display: none;
  }

  .ask-mina-btn {
    min-width: 100px;
    padding: 8px 16px;
    font-size: 13px;
  }

  .navigation-buttons {
    gap: 6px;
  }

  .nav-btn {
    padding: 8px 16px;
    min-width: unset;
    font-size: 13px;
  }

  .nav-btn.previous {
    padding: 8px 12px;
  }

  .nav-btn.next {
    padding: 8px 16px;
  }
  
  .mode-indicator {
    top: 75px; /* Pushed down significantly from 52px to 75px */
    font-size: 12px;
    padding: 4px 8px;
    right: 16px; /* Ensure it's properly positioned horizontally as well */
  }
}

/* Small Mobile Styles */
@media (max-width: 375px) {
  .question-footer {
    padding: 0 12px;
    gap: 8px;
  }

  .nav-btn {
    padding: 8px 12px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .nav-btn {
    transition: none;
  }
}

/* Mode indicator for review mode */
.mode-indicator {
  position: fixed;
  top: 60px;
  right: 12px;
  background: rgba(147, 51, 234, 0.1);
  color: var(--primary-purple, #9333EA);
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid rgba(147, 51, 234, 0.2);
  z-index: 1000;
}

/* Special positioning for mobile */
@media (max-width: 768px) {
  .mode-indicator {
    top: 90px; /* Moved down significantly */
    right: 10px;
  }
}