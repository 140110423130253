/* Add this to your Exam.css file */

/* General styles */
* {
  box-sizing: border-box;
}

body, html {

  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.Exam {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}


.question-title{
 
  font-size: 23px;
  font-weight: 600;
}
.upper-bar, .lower-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  z-index: 2;
  position: sticky;
  top: 0;
}

.lower-bar {
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
}

.content {
  background-color: #ffffff;
  display: flex;
  align-items: stretch;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 130px);
}

.mock-section {
  width: 100%; /* Width of the exam content section */
}


.left-section {
  /* padding: 100px 5% 100px; */
padding-left: 20%;
padding-right: 20%;

  width: 100%; /* Width of the exam content section */
}

.exam-page{
  background-color: #ffffff;
  /* padding: 50px 20% 100px; */
  width: 60%;
  max-width: 1200px;
  margin: 0 auto;
}

.right-section{
width: 40%;
}

.divider {
  width: 1px;
  background-color: #ddd;
  margin: 0px 20px;
  align-self: stretch;
  /* height: 100vh; */
}
.question-divider {
  border: 0;
  height: 1px;
  background-color: #ddd;
  /* margin: 50px 0; */
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
}
.passage, .question-answer {
 
  padding: 0px; /* Padding on both sides */
  display: flex;
  flex-direction: column;
}

.passage {
  flex: 1;
  overflow-y: auto;
}

.passage-box {
  height: 200px; /* Fixed height for the passage box */
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;

}

.question-title {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px; /* Reduced bottom margin */
  width: 100%;
  padding-bottom: 15px; /* Add padding to accommodate the divider */
}

.question-text {
  margin-top: 15px; /* Reduced top margin */
  padding-bottom: 30px; /* Reduced from 60px to 30px */
  width: 100%;
}

.answer-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px; /* Reduced from 20px to 15px */
  width: 100%;
  counter-reset: answer-counter;
}

.answer-option {
  cursor: pointer;
  
  padding: 30px 10px 30px 40px; /* Adjusted padding to ensure space for the number */
  position: relative;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  min-height: 50px;
}

.answer-option::before {
  content: counter(answer-counter);
  counter-increment: answer-counter;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3E00EF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}

.answer-option.correct::before {
  content: '✓';
  background-color: #4CAF50; /* Green color for correct answers */
}

.answer-option.incorrect::before {
  content: '✕';
  background-color: #F44336; /* Red color for incorrect answers */
}

.answer-option:hover, .selected {
  background-color: #e3f2fd;
}

.correct {
  background-color: #E8F5E9; /* Light green background for correct answer */
  border-color: #4CAF50;
}

.incorrect {
  background-color: #FFEBEE; /* Light red background for incorrect answer */
  border-color: #F44336;
}

.reveal-answer-button, .previous-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.previous-button {
  background-color: transparent;
  border: 1px solid #3E00EF;
  color: #3E00EF;
}

.reveal-answer-button {
  background-color: #3E00EF;
  color: white;
  border: none;
}

.floating-ask-mina-button {
  position: fixed;
  right: 20px;
  bottom: 80px;
  width: 180px; /* Adjust based on your image size */
  height: 60px; /* Adjust based on your image size */
  background-image: url('../assets/icons/askminabutton.png');
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 0;
  z-index: 1000;
}

/* Hide the button on larger screens */
@media (min-width: 769px) {
  .floating-ask-mina-button {
   
  }
}

.ask-mina-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ask-mina-modal {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.close-ask-mina {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.cancel-button {
  cursor: pointer;
}

.cancel-button img {
  width: 24px;
  height: 24px;
}

.ask-mina-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.chat-icon {
  padding-right: 10px;
  vertical-align: middle;
  width: 30px;
  height: 30px;
}

.explanation {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-left: 3px solid #3E00EF;
}

.upper-left, .upper-center, .upper-right {
  flex: 1;
  text-align: center;
}

.upper-left, .lower-left {
  text-align: left;
}

.upper-right, .lower-right, .reveal-answer-button.lower-right {
  text-align: right;
}

.hint-button {
  position: fixed;
  left: 20px;
  bottom: 80px;
  padding: 10px 20px;
  background-color: #ff4081; /* Updated to match the design */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.hint-button:hover {
  background-color: #e03570; /* Slightly darker for hover effect */
}

.reveal-answer-button[disabled] {
  background-color: #ddd; /* Disabled button background color */
  color: #aaa; /* Disabled button text color */
  cursor: not-allowed; /* Change cursor for disabled state */
}

.popup {
  position: absolute;
  bottom: 120px; /* Adjust according to the position of the button */
  right: 20px; /* Align with the button */
  background-color: #ff4081; /* Popup background color */
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  white-space: nowrap; /* Prevent text from wrapping */
  z-index: 10; /* Ensure popup is above other elements */
}

.back-button {
  background: none;
  border: none;
  color: #007aff; /* iOS blue color */
  font-size: 16px;
  cursor: pointer;  
  display: flex;
  align-items: center;
  padding: 0;
}

.back-icon {
  font-size: 24px;
  margin-right: 5px;
}

.hidden {
  display: none;
}

.hint-box, .explanation-box {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.hint-box {
  border-left: 5px solid #3E00EF;
}

.explanation-box {
  border-left: 5px solid #00D562;
}



/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is in front of other elements */
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 90%;
  width: 400px;
}

.modal-content h2 {
  margin-top: 0;
  font-size: 1.5em;
  color: #3E00EF;
}

.modal-content p {
  font-size: 1.2em;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3E00EF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

/* CSS for the report issue button */
.report-issue-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
  /* text-decoration: underline; */
  margin-top: 10px;
  align-self: flex-start;
}

/* CSS for the report issue modal */
.report-issue-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px 24px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
}
.report-issue-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.issue-option {
  margin: 16px 0;
  display: flex;
  align-items: flex-start;
}

.issue-option input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border: 2px solid #000000;
  border-radius: 50%;
  outline: none;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  margin-top: 2px;
}

.issue-option input[type="radio"]:checked::before {
  content: '';
  width: 13px;
  height: 13px;
  background-color: #3E00EF;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}




.report-issue-modal hr {
  border: none;
  height: 1px;
  background-color: #E5E7EB;
  margin: 12px -24px;
  width: calc(100% + 48px);
}


.report-issue-modal h2 {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 12px;
  color: #111827;
}


.issue-option label {
  font-size: 14px;
  color: #374151;
  cursor: pointer;
  line-height: 1.4;
  display: flex;
  align-items: center;
}

.submit-issue-button {
  background-color: transparent;
  color: #3E00EF;
  border: none;
  border-radius: 5px;
  
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  float: right;
  margin-top: 16px;
}


.report-issue-modal p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 16px;
  text-align: left;
  font-weight: 600;
}
.other-issue-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
}

.submit-issue-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
/* Mobile styles */

@media (max-width: 768px) {
  .exam-page{
    width: 100%; /* Full width for both sections */
    padding: 30px; /* Adjust padding */
}
  /* Add a flex layout to the lower-bar for better control */
  .lower-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #ddd;
  }

  .content {
    flex-direction: column; /* Stack passage and question-answer vertically */
  }

  .divider {
    display: none; /* Hide the divider */
  }

  .left-section, .right-section {
    width: 100%; /* Full width for both sections */
    padding: 30px; /* Adjust padding */
  }

  .answer-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .answer-option {
  
    width: 100%;
   height: 75px;
    padding: 30px;
    font-size: 16px;
    border: 1px solid #909090;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    border-radius: 10px;
  }

  .hint-box, .explanation-box {
    margin-top: 20px;
    text-align: center; /* Center align text */
  }

  .mobile-passage-title {
    color: #000;
    display: block; /* Display the passage title */
    text-align: left; /* Align left */
    font-weight: bold;
    margin-bottom: 10px;
  }

  .passage {
    flex: 0;
  }

  .report-issue-button {
    color: #D70009;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    background: none;
    border: none;
  }

  .lower-right {
   
    display: flex;
    flex-direction: row;
    align-items: flex-end; /* Align buttons to the right */
    gap: 10px; /* Add space between buttons */
  }

  .previous-button, .reveal-answer-button {
   
    height: 40px;
    text-align: center; /* Center text */
    font-size: 12px;
  }

  .floating-ask-mina-button {
    position: fixed;
    bottom: 50px; /* Adjust to ensure it doesn't overlap with other elements */
    right: 20px;
  }
.floating-ask-mina-button span {
  color: white;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap; /* Prevent text from wrapping */
}
  .page {
    padding: 30px 5% 100px;
  }
}

.upper-left {
  display: flex;
  align-items: center;
}

.cancel-button {
  cursor: pointer;
  
  margin-right: 10px;
}

.cancel-button img {
  width: 24px;
  height: 24px;
  
  color: #c51c1c;

}

.questions-remaining {
  font-size: 13px;
  color: #000000;
}

.cancel-button-exam {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-button-exam img {
  width: 25px;
  height: 25px;
}

.upper-left {
  display: flex;
  align-items: center;
}

.questions-remaining {
  font-size: 13px;
  color: #000000;
  margin-left: 10px;
}

/* Add this at the end of your file */
@media (max-width: 768px) {
  body, html {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
  }

  .Exam {
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  .upper-bar {
    position: sticky;
    top: 0;
    margin-top: 0;
    padding-top: 15px;
  }
}

/* Ask Mina button for large screens */
.ask-mina-button {
  height: 40px;
  border: none;
  background-color: #3E00EF;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
}

/* Floating Ask Mina button for small screens */
.floating-ask-mina-button {
  position: fixed;
  right: 0px;
  bottom: 70px;
  height: 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0px;
  z-index: 1000;
  border-radius: 0px;
}

.floating-ask-mina-button img {
  width: 100px;
  height: 40px;
  margin-right: 10px;
}

.floating-ask-mina-button span {
  color: white;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
}

/* Adjust layout for small screens */
@media (max-width: 768px) {
  .lower-center {
    display: none;
  }
  
  .floating-ask-mina-button {
    display: flex;
  }
}

/* Floating Ask Mina button for small screens */
@media (max-width: 768px) {
  .floating-ask-mina-button {
    position: fixed;
    right: 20px;
    bottom: 80px;
    height: 60px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    z-index: 1000;
    border-radius: 0px;
    
  }

  .floating-ask-mina-button img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .floating-ask-mina-button span {
    color: white;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
}

.two-column-layout {
  display: flex;
  height: calc(100vh - 130px);
}

.script-column {
  width: 40%;
  padding: 20px;
  overflow-y: auto;
  border-right: 1px solid #ddd;
}

.questions-column {
  width: 60%;
  padding: 20px;
  overflow-y: auto;
}

.script-box {
  /* background-color: #f9f9f9; */
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  /* border: 1px solid #ddd; */
}

.instruction-box {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.instruction {
  margin: 0;
  font-weight: 500;
}

.question-box {
  margin-bottom: 30px;
}

.question-number {
  font-weight: bold;
  margin-bottom: 10px;
}

/* ... (keep existing styles for question-text, answer-section, answer-option, etc.) ... */

@media (max-width: 768px) {
  .two-column-layout {
    flex-direction: column;
  }

  .script-column,
  .questions-column {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .script-column {
    padding-bottom: 120px;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .instruction-box {
    margin-bottom: 15px;
  }

  /* ... (keep existing mobile styles) ... */
}

/* Update the answer-section for questions with script */
.two-column-layout .answer-section {
  display: flex;
  
  flex-direction: column;
  gap: 15px; /* Reduced from 20px to 15px */
  width: 100%;
}

.two-column-layout .answer-option {
  width: 100%;
  padding: 25px 10px 25px 40px; /* Increased top and bottom padding */
  min-height: 0px; /* Increased minimum height */
  display: flex;
  align-items: center; /* Vertically center the content */
  font-size: 16px; /* Adjust font size if needed */
  line-height: 1.4; /* Improve readability */
}

