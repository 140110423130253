html, body {
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: #F5F5F7;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.login-container {
  margin: 160px auto 40px;
  background-color: #FFFFFF;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(147, 51, 234, 0.1);
  text-align: center;
  position: relative;
  max-width: 400px;
  width: calc(100% - 48px); /* Adjust width to account for padding */
  box-sizing: border-box;
  overflow: visible;
}

.logo-container-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 16px 24px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  z-index: 1000;
  height: 70px;
}

.login-container-box {
  padding-top: 90px;
}

.logo-container {
  position: fixed;
  top: 20px;
  left: 20px;
}

.mina-logo {
  height: 40px;
  object-fit: contain;
}

.login-title {
  margin-bottom: 36px;
  font-size: 28px !important;
  font-weight: 600 !important;
  color: #333;
}

.custom-login-title {
  position: relative;
  text-align: center;
  margin: 0 0 30px 0 !important;
  font-family: 'Inter', sans-serif !important;
  color: #111827;
}

.login-input {
  width: 100%;
  margin-bottom: 24px;
}

/* MUI Component Overrides */
.MuiOutlinedInput-root {
  border-radius: 12px !important;
  background-color: #F9FAFB;
  transition: all 0.2s ease-in-out;
}

.MuiOutlinedInput-root:hover {
  background-color: #F3F4F6;
}

.MuiOutlinedInput-root.Mui-focused {
  background-color: #fff;
  box-shadow: 0 0 0 2px rgba(147, 51, 234, 0.1);
}

.MuiButton-contained {
  background-color: #9333EA !important;
  border-radius: 12px !important;
  padding: 12px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  box-shadow: 0 4px 6px rgba(147, 51, 234, 0.25) !important;
  transition: all 0.2s ease !important;
}

.MuiButton-contained:hover {
  background-color: #7928CA !important;
  box-shadow: 0 6px 10px rgba(147, 51, 234, 0.3) !important;
  transform: translateY(-2px);
}

.MuiAlert-root {
  border-radius: 12px !important;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  gap: 12px;
}

.link-item {
  margin-bottom: 8px;
  color: #6B7280;
  text-align: center;
  width: 100%;
  font-size: 14px;
}

.link-text {
  color: #374151;
}

.link {
  font-weight: 600 !important;
  color: #9333EA !important;
  margin-left: 4px;
  transition: all 0.2s ease;
}

.link:hover {
  color: #7928CA !important;
  text-decoration: none;
}

/* Media Queries for Mobile */
@media (max-width: 600px) {
  .login-container {
    margin-top: 120px;
    padding: 32px 24px;
    border-radius: 16px;
  }
  
  .logo-container-box {
    height: 60px;
  }
  
  .mina-logo {
    height: 36px;
  }
  
  .login-title {
    font-size: 24px !important;
    margin-bottom: 24px;
  }
}
