.page {
    background-color: #f5f5f5;
    padding: 20px;
    font-family: Arial, sans-serif;
}


.diagnostic-header {
    background-color: white;
    padding: 20px;
    text-align: left;  
    border-bottom: 1px solid #ddd;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;  
}

.test-result-title {
    padding-top: 50px;

    padding-left: 20%;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    align-self: flex-start;  
}

.level-test-results-report {
    font-size: 30px;

    padding-top: 30px;
    padding-left: 20%;
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    align-self: flex-start;  
}

.diagnostic-content {
    background-color: white;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.in-depth-analysis, .score-distribution, .weak-points, .recommended-study {
    margin-bottom: 20px;
}

.analysis-title, .score-title, .weak-points-title, .recommended-study-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.analysis-content, .score-content, .weak-points-content, .recommended-study-content {
    font-size: 16px;
    color: #555;
}
.review-exam {
    padding-top: 15px;
    max-width: 800px;
    margin: auto;
}

.header-section {
    text-align: left;
    margin-bottom: 20px;
}

.study-results-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
}
.cancel-button {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancel-button img {
    width: 25px;
    height: 25px;
}

.review-quiz-completed {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 500;
}

h3 {
    font-size: 20px;
    color: #333;
    margin: 0;
}

.learning-history {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.score-circle {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 5px solid rgba(65, 61, 255, 0.1);
    /* background-color: #fff; */
    display: flex;
    justify-content: center;
align-items: center;
    transition: border 0.3s ease;
}

.score-circle:hover {
    border: 5px solid rgba(65, 61, 255, 0.2);
}

.score {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}

.score-label {
    font-weight: bold;
    font-size: 12px;
    color: #1C1C1C;
    margin-top: 5px;
    text-align: center;
}

.filter-wrong {
    margin-bottom: 20px;
}

.exam-responses {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.response-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.response-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.question-header h4 {
    font-size: 18px;
    margin: 0;
}

.time {
    font-size: 14px;
    color: #777;
}

.question-text {
    font-weight: 100;
    font-size: 16px;
    margin-bottom: 15px;
}

.answer-status {
    text-align: right;
}

.status-icon {
    width: 24px;
    height: 24px;
}

.status-icon.correcto {
    fill: #4CAF50;  /* Green color for correct answers */
}

.status-icon.incorrecto {
    fill: #F44336;  /* Red color for incorrect answers */
}

@media (max-width: 768px) {
    .exam-responses {
        grid-template-columns: 1fr;
    }
}

/* ... existing styles ... */

.question-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.question-popup {
    background-color: white;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.close-popup {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.popup-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.instruction, .script, .question, .explanation {
    margin-bottom: 15px;
}

.passage-box {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
}

.answer-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.answer-option {
    position: relative;
    padding: 20px 10px 20px 40px; /* Increased left padding to accommodate the number */
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.choice-number {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
}

.choice-text {
    flex-grow: 1;
}

.answer-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.correct-icon {
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.incorrect-icon {
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.answer-option.correct {
    background-color: #E8F5E9;
    border-color: #4CAF50;
}

.answer-option.incorrect {
    background-color: #FFEBEE;
    border-color: #F44336;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
    .question-popup {
        width: 95%;
        padding: 15px;
    }

    .answer-section {
        grid-template-columns: 1fr;
    }

    .answer-option {
        padding: 15px 15px 15px 45px;
        font-size: 14px;
    }

    .choice-number {
        left: 15px;
    }

    .answer-icon {
        width: 16px;
        height: 16px;
        right: 15px;
    }
}

.stats-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 20px;
}

.stat-box {
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
    margin: 0 0px;
   
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stat-box h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    align-self: flex-start;
}

.stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
}

.stat-value img {
    
    width: 24px;
    height: 28px;
    margin-right: 10px;
}

.score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* New styles for the time box */
.time-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.time-value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* Media query for small screens */
@media (max-width: 768px) {
    .stats-container {
        
        flex-direction: column;
    }

    .stat-box {
        width: 100%;
        height: 150px;
        margin: 0 0 20px 0;
    }

    .stat-box:last-child {
        margin-bottom: 0;
    }
}

.diagnostic-nav {
    display: flex;
    justify-content: start;
    width: 100%;
    margin-top: 20px;
}

.nav-link {
    padding: 10px 20px;
    text-decoration: none;
    color: #A2A1A1;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    margin-bottom: 50px;
    transition: border-color 0.3s ease;
}

.nav-link.active {
    border-bottom-color: #000000;
    color: #000000;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.redo-exam-button {
    background-color: #3E00EF;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.redo-exam-button:hover {
    background-color: #3000bf;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.redo-exam-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.instagram-feedback-button {
    background: linear-gradient(135deg, #833AB4 0%, #FD1D1D 50%, #FCAF45 100%);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.instagram-feedback-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.instagram-feedback-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .button-container {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    
    .redo-exam-button,
    .instagram-feedback-button {
        width: 100%;
        max-width: 280px;
    }
}
