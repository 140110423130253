/* ResultsReview.css */
.results-container {
  position: fixed;
  inset: 0;
  background: white;
  overflow-y: auto;
  padding-top: 40px;
  z-index: 1200; /* Increased z-index to be above menu buttons */
}

/* Hide all menu toggles and close buttons when results view is open */
.results-container ~ .menu-toggle,
.results-container ~ button[aria-label="Close"],
.results-container ~ button[aria-label="close"],
.results-container ~ button[aria-label="Toggle menu"] {
  display: none !important;
}

@media (max-width: 768px) {
  /* Extra specificity for mobile devices */
  body .results-container ~ .menu-toggle,
  body .results-container ~ button[aria-label="Close"],
  body .results-container ~ button[aria-label="close"],
  body .results-container ~ button[aria-label="Toggle menu"] {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }
}

.results-content {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
}

.results-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 32px 0;
  color: #111827;
}

/* Performance Summary Section */
.performance-summary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 0 auto 40px;
  max-width: 400px; /* Constrains the width to better center the elements */
  width: 100%;
}

.performance-metric {
  display: flex;
  justify-content: center;
  background-color: #F5F3FF;
  border-radius: 12px;
  padding: 20px 15px;
  box-shadow: 0 3px 10px rgba(147, 51, 234, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.performance-metric:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(147, 51, 234, 0.18);
}

.metric-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.performance-metric span {
  font-weight: 700;
  font-size: 24px;
  color: #4C1D95;
  text-align: center;
  margin-bottom: 4px;
}

.metric-label {
  font-size: 14px;
  color: #8B5CF6;
  margin-top: 4px;
  text-align: center;
  font-weight: 500;
}

/* Filter Toggle */
.filter-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
  cursor: pointer;
  text-align: left;
}

.filter-toggle input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #E9D5FF;
  margin: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.filter-toggle input[type="checkbox"]:checked {
  background: #9333EA;
  border-color: #9333EA;
}

.filter-toggle input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.filter-toggle label {
  font-size: 14px;
  color: #000;
  cursor: pointer;
}

/* Questions Grid */
.questions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 140px; /* Increased bottom margin to make space for buttons */
  width: 100%;
}

.question-card {
  border: 1px solid #E9D5FF;
  border-radius: 12px;
  padding: 20px;
  background: white;
  position: relative;
  min-height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(147, 51, 234, 0.1);
}

.question-card:hover {
  border-color: #9333EA;
  transform: translateY(-2px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.question-title {
  font-size: 15px;
  font-weight: 500;
}

.star-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #E9D5FF;
  font-size: 18px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star-btn.active {
  color: #9333EA;
}

.question-text {
  font-size: 15px;
  line-height: 1.5;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #374151;
  flex-grow: 1;
  margin-bottom: 16px;
}

.status-indicators {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  gap: 8px;
}

.status-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-icon.correct {
  color: #22C55E;
}

.status-icon.incorrect {
  color: #EF4444;
}

/* Feedback Container */
.page-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
}

.feedback-box {
  padding: 30px;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.feedback-title {
  font-size: 16px;
  font-weight: 600;
  color: #4C1D95;
  margin: 0 auto 20px;
  max-width: 400px;
}

.feedback-options {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 16px;
}

.feedback-btn {
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedback-btn:hover {
  transform: translateY(-3px);
}

.feedback-btn .emoji {
  font-size: 48px;
  display: block;
}

.feedback-btn:hover .emoji {
  transform: scale(1.1);
}

.feedback-confirmation {
  color: #047857;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
}

.claim-xp-container {
  width: 100%;
  max-width: 1000px;
  padding: 0 32px;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.claim-xp-btn {
  width: 100%;
  background: #9333EA;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(147, 51, 234, 0.2);
  transition: all 0.2s ease;
}

.claim-xp-btn:hover {
  background: #8829d0;
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(147, 51, 234, 0.25);
}

.instagram-feedback-btn {
  width: 100%;
  background: linear-gradient(135deg, #833AB4 0%, #FD1D1D 50%, #FCAF45 100%);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.instagram-feedback-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
  .results-container {
    padding-top: 30px;
  }

  .results-content {
    width: 100%;
    padding: 0 20px;
  }

  .results-title {
    font-size: 22px;
    margin-bottom: 28px;
  }
  
  /* Performance summary for mobile */
  .performance-summary {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 32px;
  }
  
  .performance-metric {
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    gap: 16px;
  }
  
  .metric-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .performance-metric span {
    font-size: 20px;
    text-align: left;
    margin-bottom: 2px;
  }
  
  .metric-label {
    margin-top: 0;
    text-align: left;
    font-size: 13px;
  }

  .questions-grid {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 120px; /* Increased for mobile as well */
  }
  
  .page-center-container {
    margin-bottom: 100px;
  }
  
  .feedback-box {
    padding: 20px 16px;
    max-width: 90%;
  }
  
  .feedback-options {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .feedback-btn .emoji {
    font-size: 40px;
  }
  
  .feedback-title {
    font-size: 15px;
    margin-bottom: 16px;
  }

  .question-card {
    min-height: 120px;
    padding: 12px;
  }

  .question-title {
    font-size: 14px;
  }

  .question-text {
    font-size: 13px;
    margin-bottom: 24px;
  }

  .status-indicators {
    bottom: 12px;
    right: 12px;
    gap: 6px;
  }

  .status-icon {
    width: 14px;
    height: 14px;
  }

  .claim-xp-container {
    width: calc(100% - 32px);
    left: 16px;
    transform: none;
    bottom: 16px;
    padding: 0;
    gap: 12px;
  }

  .claim-xp-btn, 
  .instagram-feedback-btn {
    padding: 14px;
    font-size: 14px;
    border-radius: 8px;
  }
}

@media (max-width: 375px) {
  .results-container {
    padding-top: 12px;
  }
  
  .results-content {
    padding: 0 16px;
  }
  
  .question-card {
    min-height: 130px;
    padding: 16px;
  }
  
  .results-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}