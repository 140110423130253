.locked-exam-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.locked-exam-modal {
    background-color: white;
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.modal-content {
    text-align: center;
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 10px;
}

.modal-content p {
    margin: 0 0 20px;
}

.get-full-access-button {
    background-color: #413dff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.get-full-access-button:hover {
    background-color: #272499;
}
