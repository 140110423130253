/* Add this to your PersonalizedExam.css file */

/* General styles */
* {
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
   
    height: 100%; /* Ensure full height */
}

.Exam {
    
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    /* overflow: hidden;  */
}

.upper-bar, .lower-bar {
    flex-shrink: 0; /* Prevent bars from shrinking */
    position: sticky; /* Make bars sticky */
    z-index: 10; /* Ensure bars stay on top */
}

.upper-bar {
    top: 0;
}

.lower-bar {
    bottom: 0;
}

.content {
    overflow: hidden;
    flex-grow: 1;
    overflow-y: auto; /* Allow scrolling in the content area */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    padding: 20px;
    position: relative; /* Create a new stacking context */
    z-index: 1; /* Lower than the bars */
}

.cancel-button-exam {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
   
    top: 0;
    left: 0;
}

.cancel-button-exam img {
    width: 25px;
    height: 25px;
}
.personalised_content {
    margin: 100px;
    background-color: #ffffff;
    display: flex;
    align-items: stretch;
    overflow-y: auto;
    height: calc(100vh - 130px); /* Adjust for upper and lower bar heights */
   
  }

.personalized-question-title {
    font-size: 23px;
    font-weight: 600;
}

.other-issue-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: vertical;
  }
  
  .submit-issue-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
.personalized-upper-bar, .personalized-lower-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    z-index: 2;
    position: sticky;
    top: 0;
}

.personalized-lower-bar {
    border-top: 1px solid #ddd;
    position: sticky;
    bottom: 0;
}

.personalized-content {
    display: flex;
    align-items: stretch;
    overflow-y: auto;
    height: calc(100vh - 130px); /* Adjust for upper and lower bar heights */
}

.personalized-mock-section {
    width: 100%; /* Width of the exam content section */
}

.personalized-left-section {
    padding: 100px 5% 100px;
    width: 60%; /* Width of the exam content section */
}

.personalized-page {
    background-color: white;
    padding: 50px 20% 100px;
    width: 100%; /* Width of the exam content section */
}

.personalized-right-section {
    width: 40%;
}

.personalized-divider {
    width: 1px;
    background-color: #ddd;
    margin: 0px 20px;
    align-self: stretch;
    /* height: 100vh; */
}

.personalized-passage, .personalized-question-answer {
    padding: 0px; /* Padding on both sides */
    display: flex;
    flex-direction: column;
}

.personalized-passage {
    flex: 1;
    overflow-y: auto;
}

.personalized-passage-box {
    height: 200px; /* Fixed height for the passage box */
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.personalized-question-title {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.personalized-question-text {
    margin-top: 30px;
    padding-bottom: 60px;
    width: 100%;
}

.personalized-answer-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    counter-reset: answer-counter;
}

.personalized-answer-option {
    cursor: pointer;
    padding: 20px 10px 20px 40px; /* Adjusted padding to ensure space for the number */
    position: relative;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    min-height: 50px;
}

.personalized-answer-option::before {
    content: counter(answer-counter);
    counter-increment: answer-counter;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #3E00EF;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
}

.personalized-answer-option.correct::before {
    content: '✓';
    background-color: #4CAF50; /* Green color for correct answers */
}

.personalized-answer-option.incorrect::before {
    content: '✕';
    background-color: #F44336; /* Red color for incorrect answers */
}

.personalized-answer-option:hover, .personalized-selected {
    background-color: #e3f2fd;
}

.personalized-correct {
    background-color: #E8F5E9; /* Light green background for correct answer */
    border-color: #4CAF50;
}

.personalized-incorrect {
    background-color: #FFEBEE; /* Light red background for incorrect answer */
    border-color: #F44336;
}

.personalized-reveal-answer-button, .personalized-previous-button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.personalized-previous-button {
    background-color: transparent;
    border: 1px solid #3E00EF;
    color: #3E00EF;
}

.personalized-reveal-answer-button {
    background-color: #3E00EF;
    color: white;
    border: none;
}

.personalized-floating-ask-mina-button {
    font-size: 15px;
    color: white;
    position: fixed;
    right: 20px;
    bottom: 80px;
    width: 120px; /* Width should match the actual image width */
    height: 80px; /* Height should match the actual image height */
    background: url('/src/assets/icons/askminabutton.png') no-repeat center center; /* Use the uploaded image */
    background-size: contain; /* Ensure the entire image fits within the button */
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.personalized-ask-mina-text {
    color: white;
    font-size: 16px;
    font-weight: bold;
    position: relative;
}

.personalized-chat-icon {
    padding-right: 10px;
    vertical-align: middle;
    width: 30px;
    height: 30px;
}

.personalized-explanation {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-left: 3px solid #3E00EF;
}

.personalized-upper-left, .personalized-upper-center, .personalized-upper-right {
    flex: 1;
    text-align: center;
}

.personalized-upper-left, .personalized-lower-left {
    text-align: left;
}

.personalized-upper-right, .personalized-lower-right, .personalized-reveal-answer-button.lower-right {
    text-align: right;
}

.personalized-hint-button {
    position: fixed;
    left: 20px;
    bottom: 80px;
    padding: 10px 20px;
    background-color: #ff4081; /* Updated to match the design */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.personalized-hint-button:hover {
    background-color: #e03570; /* Slightly darker for hover effect */
}

.personalized-reveal-answer-button[disabled] {
    background-color: #ddd; /* Disabled button background color */
    color: #aaa; /* Disabled button text color */
    cursor: not-allowed; /* Change cursor for disabled state */
}

.personalized-popup {
    position: absolute;
    bottom: 120px; /* Adjust according to the position of the button */
    right: 20px; /* Align with the button */
    background-color: #ff4081; /* Popup background color */
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    white-space: nowrap; /* Prevent text from wrapping */
    z-index: 10; /* Ensure popup is above other elements */
}

.personalized-back-button {
    background: none;
    border: none;
    color: #007aff; /* iOS blue color */
    font-size: 16px;
    cursor: pointer;  
    display: flex;
    align-items: center;
    padding: 0;
}

.personalized-back-icon {
    font-size: 24px;
    margin-right: 5px;
}

.personalized-hidden {
    display: none;
}

.personalized-hint-box, .personalized-explanation-box {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.personalized-hint-box {
    border-left: 5px solid #3E00EF;
}

.personalized-explanation-box {
    border-left: 5px solid #00D562;
}

/* Modal styles */
.personalized-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is in front of other elements */
}

.personalized-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 90%;
    width: 400px;
}

.personalized-modal-content h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #3E00EF;
}

.personalized-modal-content p {
    font-size: 1.2em;
}

.personalized-modal-content button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3E00EF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

/* CSS for the report issue button */
.personalized-report-issue-button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 16px;
    /* text-decoration: underline; */
    margin-top: 10px;
    align-self: flex-start;
}

/* CSS for the report issue modal */
.personalized-report-issue-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px 24px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    width: 90%;
    max-width: 500px;
}

.personalized-report-issue-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.personalized-issue-option {
    margin: 16px 0;
    display: flex;
    align-items: flex-start;
}

.personalized-issue-option input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border: 2px solid #000000;
    border-radius: 50%;
    outline: none;
    margin-right: 12px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    margin-top: 2px;
}

.personalized-issue-option input[type="radio"]:checked::before {
    content: '';
    width: 13px;
    height: 13px;
    background-color: #3E00EF;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.personalized-report-issue-modal hr {
    border: none;
    height: 1px;
    background-color: #E5E7EB;
    margin: 12px -24px;
    width: calc(100% + 48px);
}

.personalized-report-issue-modal h2 {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 12px;
    color: #111827;
}

.personalized-issue-option label {
    font-size: 14px;
    color: #374151;
    cursor: pointer;
    line-height: 1.4;
    display: flex;
    align-items: center;
}

.personalized-submit-issue-button {
    background-color: transparent;
    color: #3E00EF;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    float: right;
    margin-top: 16px;
}

.personalized-report-issue-modal p {
    font-size: 14px;
    color: #000000;
    margin-bottom: 16px;
    text-align: left;
    font-weight: 600;
}

/* Mobile styles */
@media (max-width: 768px) {
    /* Add a flex layout to the lower-bar for better control */
    .personalized-lower-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-top: 1px solid #ddd;
    }

    .personalized-content {
        flex-direction: column; /* Stack passage and question-answer vertically */
    }

    .personalized-divider {
        display: none; /* Hide the divider */
    }

    .personalized-left-section, .personalized-right-section {
        width: 100%; /* Full width for both sections */
        padding: 30px; /* Adjust padding */
    }

    .personalized-answer-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .personalized-answer-option {
        width: 100%;
        padding: 30px;
        font-size: 16px;
        border: 1px solid #909090;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .personalized-hint-box, .personalized-explanation-box {
        margin-top: 20px;
        text-align: center; /* Center align text */
    }

    .personalized-mobile-passage-title {
        color: #000;
        display: block; /* Display the passage title */
        text-align: left; /* Align left */
        font-weight: bold;
        margin-bottom: 10px;
    }

    .personalized-passage {
        flex: 0;
    }

    .personalized-report-issue-button {
        color: #D70009;
        cursor: pointer;
        font-size: 15px;
        text-decoration: none;
        background: none;
        border: none;
    }

    .personalized-lower-right {
        display: flex;
        flex-direction: row;
        align-items: flex-end; /* Align buttons to the right */
        gap: 10px; /* Add space between buttons */
    }

    .personalized-previous-button, .personalized-reveal-answer-button {
        height: 40px;
        text-align: center; /* Center text */
        font-size: 12px;
    }

    .personalized-floating-ask-mina-button {
        position: fixed;
        bottom: 70px; /* Adjust to ensure it doesn't overlap with the lower bar */
        right: 20px;
        z-index: 11; /* Ensure it's above other elements */
    }

    .content {  
        padding-bottom: 60px; /* Add extra padding at the bottom to prevent content from being hidden behind the lower bar */
    }
}

.timer {
  font-size: 16px;
  /* font-weight: regular; */
  color: #000000;
}

.two-column-layout {
  display: flex;
  height: calc(100vh - 130px);
}

.script-column {
  width: 40%;
  padding: 20px;
  overflow-y: auto;
  border-right: 1px solid #ddd;
}

.questions-column {
  width: 60%;
  padding: 20px;
  overflow-y: auto;
}

.instruction-box {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.instruction {
  margin: 0;
  font-weight: 500;
}

.question-box {
  margin-bottom: 30px;
}

/* ... keep existing styles for question-text, answer-section, answer-option, etc. ... */

@media (max-width: 768px) {
  .two-column-layout {
    flex-direction: column;
  }

  .script-column,
  .questions-column {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .script-column {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .instruction-box {
    margin-bottom: 15px;
  }

  /* ... keep existing mobile styles ... */
}

/* Styles for two-column layout (questions with scripts) in personalized exam */
.two-column-layout .answer-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.two-column-layout .answer-option {
  width: 100%;
  padding: 25px 10px 25px 40px; /* Increased top and bottom padding */
  min-height: 80px; /* Increased minimum height */
  display: flex;
  align-items: center; /* Vertically center the content */
  font-size: 16px; /* Adjust font size if needed */
  line-height: 1.4; /* Improve readability */
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.two-column-layout .answer-option::before {
  content: counter(answer-counter);
  counter-increment: answer-counter;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #3E00EF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}

.two-column-layout .answer-option:hover,
.two-column-layout .answer-option.selected {
  background-color: #e3f2fd;
}

.two-column-layout .answer-option.correct {
  background-color: #E8F5E9; /* Light green background for correct answer */
  border-color: #4CAF50;
}

.two-column-layout .answer-option.incorrect {
  background-color: #FFEBEE; /* Light red background for incorrect answer */
  border-color: #F44336;
}

@media (max-width: 768px) {
  /* ... existing mobile styles ... */

  .two-column-layout .answer-option {
    width: 100%;
    height: 75px;
    padding: 30px;
    font-size: 16px;
    border: 1px solid #909090;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.script-box {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  line-height: 1.6; /* Increase line height for better readability */
}

.question-text {
  margin-top: 15px;
  padding-bottom: 30px; /* Reduced from 60px to 30px */
  width: 100%;
}

.personalized-answer-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px; /* Reduced from 20px to 15px */
  width: 100%;
  counter-reset: answer-counter;
}

/* For the two-column layout (questions with scripts) */
.two-column-layout .personalized-answer-section {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Reduced from 20px to 15px */
  width: 100%;
}

.personalized-answer-option,
.two-column-layout .personalized-answer-option {
  width: 100%;
  padding: 25px 10px 25px 40px; /* Increased top and bottom padding */
  min-height: 80px; /* Increased minimum height */
  display: flex;
  align-items: center; /* Vertically center the content */
  font-size: 16px; /* Adjust font size if needed */
  line-height: 1.4; /* Improve readability */
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  /* ... (keep existing mobile styles) ... */

  .script-box {
    line-height: 1.4; /* Slightly reduce line height for mobile */
  }

  .personalized-answer-section,
  .two-column-layout .personalized-answer-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .personalized-answer-option,
  .two-column-layout .personalized-answer-option {
    width: 100%;
    height: 75px;
    padding: 30px;
    font-size: 16px;
    border: 1px solid #909090;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

/* Add these new styles */
.script-image {
  margin: 20px 0;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.script-image img {
  max-width: 100%;
  height: auto;
  border-radius: 3px;
}

.answer-image {
  margin: 20px 0;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.answer-image img {
  max-width: 100%;
  height: auto;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .script-image, .answer-image {
    margin: 15px 0;
    padding: 15px;
  }
}
