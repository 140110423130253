/* ===============================================
   PART 1: QUESTION VIEW BASE STYLES 
   =============================================== */

/* Loading State */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 24px;
  text-align: center;
}

.loading-spinner {
  width: 48px;
  height: 48px;
  border: 4px solid rgba(147, 52, 233, 0.2);
  border-radius: 50%;
  border-top-color: var(--primary-purple, #9333EA);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 16px;
}

.loading-container p {
  color: #6B7280;
  font-size: 16px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Report Button and Ask Mina Button */
.report-btn {
  color: #EF4444;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  pointer-events: auto !important;
  z-index: 1002 !important;
}

.ask-mina-btn {
  display: none;
  padding: 8px 24px;
  background: var(--primary-purple, #9333EA);
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  justify-self: center;
  pointer-events: auto !important;
  z-index: 1002 !important;
}

.question-view.answered .ask-mina-btn {
  display: flex !important;
}

/* Navigation Buttons */
.navigation-buttons {
  display: flex;
  gap: 8px;
  justify-self: end;
  pointer-events: auto !important;
  z-index: 1002 !important;
}

.nav-btn {
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer !important;
  transition: all 0.2s ease;
  pointer-events: auto !important;
  z-index: 1002 !important;
}

.nav-btn.previous {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  color: #6B7280;
}

.nav-btn.next {
  background: #9333EA;
  color: white;
  border: none;
  min-width: 140px;
}

/* Modals */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  z-index: 9999 !important;
  pointer-events: auto !important;
}

.modal-content {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 400px;
  z-index: 10000 !important;
  pointer-events: auto !important;
}

.modal-content h3 {
  font-size: 18px;
  font-weight: 500;
  color: #111827;
  margin-bottom: 8px;
}

.modal-content p {
  color: #6B7280;
  font-size: 14px;
  margin-bottom: 24px;
}

.modal-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.modal-button {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer !important;
  pointer-events: auto !important;
  z-index: 10001 !important;
}

.modal-button.primary {
  background: var(--primary-purple, #9333EA);
  color: #FFFFFF;
  border: none;
}

.modal-button.secondary {
  border: 1px solid #E5E7EB;
  background: #FFFFFF;
  color: #6B7280;
}

/* Report Modal */
.report-modal {
  background: #FFFFFF;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  z-index: 10000 !important;
  pointer-events: auto !important;
}

.report-modal h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #111827;
  margin-bottom: 8px;
}

.report-modal h3 button {
  background: none;
  border: none;
  cursor: pointer;
  color: #6B7280;
  font-size: 16px;
  padding: 4px;
}

.report-modal h4 {
  font-size: 14px;
  color: #6B7280;
  margin-bottom: 16px;
}

/* Report Options */
.report-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.report-option {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
}

.report-option input[type="radio"] {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  cursor: pointer;
  accent-color: var(--primary-purple, #9333EA);
}

.report-option label {
  font-size: 14px;
  color: #111827;
  cursor: pointer;
  line-height: 1.4;
}

/* Other Description Field */
.other-description {
  margin-top: 16px;
  animation: slideDown 0.3s ease;
}

.other-description label {
  display: block;
  font-size: 14px;
  color: #374151;
  margin-bottom: 8px;
}

.other-description textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
  transition: border-color 0.2s ease;
  font-family: inherit;
}

.other-description textarea::placeholder {
  font-family: inherit;
  color: #6B7280;
  font-size: 14px;
}

.other-description textarea:focus {
  outline: none;
  border-color: var(--primary-purple, #9333EA);
  box-shadow: 0 0 0 2px rgba(147, 52, 233, 0.1);
}

.submit-issue {
  width: 100%;
  padding: 12px;
  margin-top: 24px;
  background: #9333EA;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-issue:disabled {
  background: #E5E7EB;
  cursor: not-allowed;
}

/* Mobile Styles for Base Components */
@media (max-width: 640px) {
  .report-text {
    display: none;
  }

  .nav-btn {
    padding: 8px 16px;
    font-size: 13px;
    min-width: unset;
  }

  .nav-btn.next {
    min-width: unset;
    width: auto;
    padding: 8px 16px;
    font-size: 13px;
  }

  .question-view.answered .ask-mina-btn {
    display: block;
    grid-column: 2;
    min-width: 100px;
    padding: 8px 16px;
  }

  .modal-content {
    margin: 16px;
  }

  .report-modal {
    margin: 16px;
    max-width: calc(100% - 32px);
  }

  .modal-overlay {
    padding: 0;
  }
}

/* Animations */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .question-view-chat,
  .question-view-chat *,
  .nav-btn,
  .modal-button,
  .submit-issue,
  .other-description {
    transition: none !important;
    animation: none !important;
  }
}

/* ===============================================
   PART 2: CONTEXT CHAT STYLES 
   =============================================== */

/* Chat Overlay */
.context-chat-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.context-chat-overlay.visible {
  opacity: 1;
  pointer-events: auto;
}

/* Chat Container */
.context-chat {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  z-index: 9999;
}

.context-chat.visible {
  transform: translateX(0);
}

/* Chat Header */
.context-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #e5e7eb;
}

.context-chat-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #111827;
}

.context-chat-close {
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  font-size: 24px;
  line-height: 1;
}

/* Messages Area */
.context-chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Message Styling */
.context-chat-message {
  display: flex;
  max-width: 85%;
  padding: 12px 16px;
  border-radius: 16px;
  position: relative;
  gap: 12px;
}

.context-chat-message.user {
  align-self: flex-end;
  background-color: #9333ea;
  color: white;
  border-bottom-right-radius: 4px;
}

.context-chat-message.bot {
  align-self: flex-start;
  background-color: #f3f4f6;
  color: #111827;
  border-bottom-left-radius: 4px;
}

.message-content {
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-line;
}

/* Bot Avatar */
.bot-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.avatar-logo {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

/* Typing Indicator */
.typing-indicator {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.typing-indicator .dot {
  width: 8px;
  height: 8px;
  background-color: #9333ea;
  border-radius: 50%;
  opacity: 0.7;
  animation: typingAnimation 1.4s infinite ease-in-out;
}

.typing-indicator .dot:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typingAnimation {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-5px);
  }
}

/* Input Area */
.context-chat-input-container {
  display: flex;
  padding: 16px;
  border-top: 1px solid #e5e7eb;
  gap: 12px;
  align-items: center;
}

/* Make form full width on all devices */
.context-chat-input-container form {
  display: flex;
  width: 100%;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 24px;
  padding: 4px 8px 4px 16px;
  align-items: center;
  gap: 8px;
}

.context-chat-input-container input {
  flex: 1;
  height: 40px;
  padding: 0;
  border: none;
  font-size: 14px;
  outline: none;
  background: transparent;
}

.context-chat-input-container input:focus {
  border-color: transparent;
  box-shadow: none;
}

.send-message {
  width: 40px;
  height: 40px;
  background-color: #9333ea;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  flex-shrink: 0;
}

.send-message:hover {
  background-color: #7c28d0;
}

.send-message:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

/* Keyboard Open State (for mobile) */
.context-chat.keyboard-open {
  height: 50vh;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .context-chat {
    width: 100%;
  }
  
  .context-chat-messages {
    padding: 12px;
  }
  
  .context-chat-message {
    max-width: 90%;
    padding: 10px 14px;
  }
  
  .context-chat-input-container {
    padding: 12px;
  }
  
  .context-chat-input-container form {
    display: flex;
    width: 100%;
  }
  
  .context-chat-input-container input {
    -webkit-user-select: text !important;
    user-select: text !important;
    font-size: 16px !important;
    border: none;
    height: 40px;
    flex: 1;
    background: transparent;
    outline: none;
  }
  
  .context-chat-input-container {
    display: flex;
    width: 100%;
  }
  
  .send-message {
    width: 40px;
    height: 40px;
    background-color: #7F5AF0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    flex-shrink: 0;
  }
  
  /* Make the mobile container match ChatView style */
  .context-chat form {
    width: 100%;
    display: flex;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
  }
}