/* Import the provided chat styling */
:root {
  --sidebar-width: 250px;
  --primary-purple: #3E00EF;
  --dark-purple: #3200cc;
  --lightest-purple: #f0ebff;
}

/* ===============================================
   PART 1: GLOBAL LAYOUT
   =============================================== */
.chat-view {
  position: fixed;
  inset: 0;
  left: var(--sidebar-width);
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 100;
  isolation: isolate;
}

.chat-view,
.chat-view * {
  touch-action: auto !important;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.chat-view input,
.chat-view textarea {
  -webkit-user-select: text !important;
  user-select: text !important;
  font-size: 16px !important;
}

/* ===============================================
   PART 2: HISTORY COMPONENTS
   =============================================== */
.history-toggle {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: #f3f4f6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 102;
}

.history-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 280px;
  background: white;
  border-left: 1px solid #e5e7eb;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.history-panel.visible {
  transform: translateX(0);
}

.history-header {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history-header h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.history-close {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.new-chat-btn {
  margin: 16px;
  padding: 10px 16px;
  background: var(--primary-purple);
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.new-chat-btn:hover {
  background: var(--dark-purple);
}

.history-items {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.history-section {
  margin-bottom: 24px;
}

.history-date {
  font-size: 12px;
  color: #6b7280;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
}

.history-item {
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;
  margin-bottom: 8px;
}

.history-item:hover {
  background: #f3f4f6;
}

.history-item h4 {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px 0;
  color: #111827;
}

.history-item p {
  font-size: 12px;
  color: #6b7280;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-time {
  font-size: 10px;
  color: #9ca3af;
  position: absolute;
  top: 12px;
  right: 12px;
}

.unread-indicator {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 8px;
  height: 8px;
  background: var(--primary-purple);
  border-radius: 50%;
}

.history-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #9ca3af;
  font-size: 14px;
}

/* ===============================================
   PART 3: MAIN CHAT AREA
   =============================================== */
.chat-main {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  padding-bottom: 180px;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.messages-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 60px;
}

/* Welcome Screen */
.welcome-screen {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  padding: 0 20px;
  width: 100%;
  z-index: 1;
}

.welcome-icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.welcome-screen h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

/* ===============================================
   PART 4: MESSAGES
   =============================================== */
.message {
  display: flex;
  gap: 12px;
  max-width: 80%;
}

.user-message {
  margin-left: auto;
  flex-direction: row-reverse;
}

.bot-avatar {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
}

.avatar-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.message-bubble {
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 1.5;
  background: #F3F4F6;
}

.user-message .message-bubble {
  background: var(--primary-purple, #9334E9);
  color: white;
}

/* ===============================================
   PART 5: INPUT AREA
   =============================================== */
.chat-input-container {
  position: fixed;
  left: var(--sidebar-width);
  right: 0;
  bottom: 0;
  background: white;
  border-top: 1px solid #e5e7eb;
  padding: 16px;
  z-index: 101;
}

.chat-input-form {
  max-width: 800px;
  margin: 0 auto;
}

/* Action Buttons */
.input-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 12px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  background: #f3f4f6;
  cursor: pointer;
  white-space: nowrap;
}

/* Input Wrapper */
.input-wrapper {
  display: flex;
  gap: 8px;
  background: #f3f4f6;
  padding: 8px 16px;
  border-radius: 24px;
}

.input-wrapper input {
  flex: 1;
  border: none;
  background: transparent;
  font-size: 14px;
  outline: none;
}

.input-actions {
  display: flex;
  gap: 8px;
}

.attach-btn,
.send-btn {
  padding: 8px;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Make sure send button is always visible on mobile */
@media (max-width: 768px) {
  .send-btn {
    color: var(--primary-purple);
    background-color: var(--lightest-purple);
  }
  
  .send-btn:disabled {
    opacity: 0.7;
  }
}

/* ===============================================
   PART 6: MOBILE STYLES
   =============================================== */
@media (max-width: 768px) {
  .chat-view {
    left: 0;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
  }

  .chat-main {
    padding: 0 24px 140px 24px;
  }
  
  .chat-header {
    padding: 12px 24px 12px 24px;
  }

  .message {
    max-width: 85%;
  }

  .chat-input-container {
    left: 0;
    padding: 12px;
    background: white;
  }

  .input-buttons {
    justify-content: flex-start;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 8px;
  }

  .action-btn {
    padding: 6px 12px;
    font-size: 13px;
    flex-shrink: 0;
  }

  /* Input area mobile overrides */
  .chat-input-form {
    padding: 0;
  }

  .input-wrapper {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
  }

  .input-wrapper input {
    padding: 8px 12px;
  }

  .attach-btn,
  .send-btn {
    width: 36px;
    height: 36px;
  }

  .history-panel {
    width: 100%;
    max-width: 280px;
  }
}

/* Extra Small Screens */
@media (max-width: 375px) {
  .chat-main {
    padding: 0 16px 140px 16px;
  }
  
  .chat-header {
    padding: 12px 16px 12px 16px;
  }
}

/* ===============================================
   PART 7: KEYBOARD & DEVICE SPECIFIC
   =============================================== */
/* iOS Specific */
@supports (-webkit-touch-callout: none) {
  .chat-view {
    height: -webkit-fill-available;
  }

  .chat-input-container {
    padding-bottom: calc(16px + env(safe-area-inset-bottom, 0));
  }
}

/* Keyboard Open */
.chat-view.keyboard-open {
  height: 100%;
  position: fixed;
}

.chat-view.keyboard-open .chat-main {
  padding-bottom: 80px;
}

.chat-view.keyboard-open .chat-input-container {
  position: fixed;
  bottom: 0;
}

/* When keyboard is open */
@media (max-height: 600px) {
  .chat-view {
    position: fixed;
    height: 100%;
  }

  .chat-input-container {
    position: fixed;
  }
}

/* ===============================================
   PART 8: UTILITIES
   =============================================== */
.history-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

.history-overlay.visible {
  opacity: 1;
  pointer-events: auto;
}

/* Hide scrollbars */
.chat-main,
.history-items {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.chat-main::-webkit-scrollbar,
.history-items::-webkit-scrollbar {
  display: none;
}

/* Text Selection Prevention */
.bot-avatar,
.history-toggle,
.history-close,
.send-btn,
.attach-btn,
.action-btn {
  user-select: none;
  -webkit-user-select: none;
}

/* Ensure proper stacking on mobile */
.chat-view {
  isolation: isolate;
}

/* Prevent background interactions when history is open */
.chat-view.history-open {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Fix for Android keyboard issues */
.chat-view.android-keyboard-open {
  height: 100%;
  position: fixed;
  bottom: 0;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .history-panel,
  .history-overlay,
  .chat-view * {
    transition: none !important;
    animation: none !important;
  }
}

/* ===============================================
   PART 9: CHAT STATES & FEEDBACK
   =============================================== */
/* Chat state indicators */
.chat-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: #6b7280;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary-purple);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.chat-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: #EF4444;
}

.chat-error button {
  margin-top: 16px;
  padding: 8px 16px;
  background: var(--primary-purple);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

/* Message status */
.message-status {
  display: block;
  font-size: 10px;
  margin-top: 4px;
  opacity: 0.7;
}

.message-status.sending {
  color: #9ca3af;
}

.message-status.failed {
  color: #EF4444;
  display: flex;
  align-items: center;
  gap: 4px;
}

.retry-btn {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: inherit;
}

/* Typing indicator */
.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 24px;
  width: 100%;
}

.typing-indicator .dot {
  width: 6px;
  height: 6px;
  background: #6b7280;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
  display: inline-block;
}

.typing-indicator .dot:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}

/* Chat modes */
.chat-view[data-mode="deepthink"] .action-btn:first-child {
  background: var(--lightest-purple);
  color: var(--primary-purple);
  font-weight: 500;
}

.chat-view[data-mode="search"] .action-btn:nth-child(2) {
  background: var(--lightest-purple);
  color: var(--primary-purple);
  font-weight: 500;
}

.action-btn.active {
  background: var(--lightest-purple) !important;
  color: var(--primary-purple) !important;
  font-weight: 500 !important;
}

/* Page container */
.page-container {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.main-content {
  flex: 1;
  margin-left: 250px; /* Width of the sidebar */
  position: relative;
}

/* Mobile menu icon */
.mobile-menu-icon {
  position: fixed;
  top: 16px;
  left: 16px;
  font-size: 24px;
  cursor: pointer;
  z-index: 101;
  color: #333;
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-icon {
    display: block;
  }
  
  .main-content {
    margin-left: 0;
  }
}