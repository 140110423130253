/* Menu Toggle Button */
.menu-toggle {
  display: none;
  position: fixed;
  top: 16px;
  left: 16px;
  z-index: 1100;
  background: white;
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Overlay */
.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Sidebar Base */
.sidebar {
  width: 240px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  border-right: 1px solid #E5E7EB;
  display: flex;
  flex-direction: column;
  padding: 16px;
  z-index: 1000;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

/* Header */
.sidebar-header {
  margin-bottom: 32px;
  padding: 16px 8px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.logo-text {
  font-weight: 600;
  color: #111827;
  font-size: 20px;
}

/* Navigation */
.nav-items {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  border-radius: 8px;
  text-decoration: none;
  color: #6B7280;
  transition: 0.2s ease;
  height: 40px;
}

.nav-item:hover {
  background: rgba(127, 90, 240, 0.08);
}

.nav-item.active {
  background: #9333EA;
  color: white;
}

.nav-item .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-shrink: 0;
  line-height: 1;
}

.nav-item .label {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 500;
  display: flex;
  align-items: center;
}

/* Footer */
.sidebar-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
  border-top: 1px solid #E5E7EB;
}

/* Get Full Access */
.get-access-btn {
  width: 100%;
  padding: 12px;
  background: #9333EA;
  color: white;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 12px;
}

.get-access-btn .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
}

.get-access-text {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 500;
}

/* Premium Indicator */
.premium-indicator {
  width: 100%;
  padding: 12px;
  background: rgba(147, 51, 234, 0.1);
  color: #9333EA;
  border: 1px solid rgba(147, 51, 234, 0.2);
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 12px;
}

.premium-indicator .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  color: #9333EA;
}

.premium-text {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 500;
}

/* Settings Navigation Item */
.settings-nav-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  border-radius: 8px;
  text-decoration: none;
  color: #6B7280;
  transition: 0.2s ease;
  height: 40px;
}

.settings-nav-item:hover {
  background: rgba(127, 90, 240, 0.08);
}

.settings-nav-item .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
}

.settings-nav-item .label {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 500;
  display: flex;
  align-items: center;
}

/* Logout button */
.logout-button {
  margin-top: 12px;
  width: 100%;
  padding: 10px;
  background: #f3f4f6;
  color: #4b5563;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background: #e5e7eb;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  /* Open State */
  .sidebar.open {
    transform: translateX(0);
    width: 280px;
    height: 100%;
    overflow-y: auto;
  }

  .sidebar.mobile-active {
    transform: translateX(0);
    width: 280px;
    height: 100%;
    overflow-y: auto;
  }

  .sidebar.open .sidebar-content {
    padding-top: 48px; /* Space for burger menu */
  }

  .sidebar.open .logo-text,
  .sidebar.open .label,
  .sidebar.open .get-access-text {
    display: inline-block;
  }

  .sidebar.open .nav-item,
  .sidebar.open .settings-nav-item {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .sidebar.open .get-access-btn,
  .sidebar.open .premium-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 8px;
  } 

  .sidebar-overlay {
    display: block;
  }

  .nav-items {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

/* Hide scrollbar */
.nav-items {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.nav-items::-webkit-scrollbar {
  display: none;
}

/* Override global hover transform for the sidebar */
.sidebar .nav-item:hover,
.sidebar .settings-nav-item:hover,
.sidebar .resource-nav-item:hover,
.sidebar .get-access-btn:hover {
  transform: none !important;
}

/* Resources Section */
.sidebar-resources {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
  border-top: 1px solid #E5E7EB;
}

.resource-nav-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  border-radius: 8px;
  text-decoration: none;
  color: #6B7280;
  transition: 0.2s ease;
  height: 40px;
}

.resource-nav-item:hover {
  background: rgba(127, 90, 240, 0.08);
}

.resource-nav-item .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
}

.resource-nav-item .label {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 500;
  display: flex;
  align-items: center;
}

/* Streak container */
.streak-container {
  padding: 12px 16px;
  color: #666;
  font-size: 14px;
  text-align: center;
  background-color: #F5F5F5;
  border-radius: 8px;
  margin: 8px 0;
}

.streak-count {
  font-weight: 500;
  color: #333;
}