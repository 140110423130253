.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 80px;
}

.content {
  flex: 1;
  overflow-y: auto;
}

h1, h2 {
  text-align: center;
}
.question-category {
  font-size: 14px;
  color: #000000;
  margin-top: 15px;
  font-style: italic;
}
.selectors {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.selectors select {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.questions-list {
  list-style-type: none;
  padding: 0;
}

.question-item {
  padding: 10px;
  margin: 5px 0;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.question-item.selected {
  background-color: #604caf !important;
  color: white !important;
}

.question-item.green {
  background-color: #90EE90;
}

.question-item.red {
  background-color: #FFB6C1;
}

.question-item.orange {
  background-color: #FFA500;
}

.button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button.secondary {
  background-color: #6c757d;
  color: white;
}

.button.active {
  background-color: #28a745;
  color: white;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.loading {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
}

.section-buttons {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.section-button {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-size: 0.9em;
}

.section-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.section-button:hover {
  background-color: #e8e8e8;
}

.section-button.active:hover {
  background-color: #0056b3;
}
