/* Design System - Variables and shared styles matching "new front" */
:root {
  --primary-purple: #9334E9; /* Standardized to match new front */
  --primary-purple-dark: #7828C8;
  --primary-purple-light: #A855F7;
  --light-purple: #E9D5FF;
  --lightest-purple: #F5EEFF;
  --background: #F9FAFB;
  --text-primary: #111827;
  --text-secondary: #6B7280;
  --white: #FFFFFF;
  --border-color: #E5E7EB;
  --border-radius-lg: 12px;
  --border-radius-md: 8px;
  --border-radius-pill: 999px;
  --sidebar-width: 240px;
  --spacing-base: 8px;
  
  /* Standard padding values */
  --padding-desktop: 24px 32px;
  --padding-mobile: 16px;
  --padding-xsmall: 12px;
  
  /* Standard margins */
  --margin-section: 24px;
  --margin-element: 16px;
}

/* Base styles */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-primary);
  background-color: var(--white);
  overflow-x: hidden;
}

/* Layout Adjustments for Question Views */
.question-layout {
  position: fixed;
  inset: 0;
  background: var(--white);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.question-header {
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  background: var(--white);
  position: relative;
}

.question-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px 16px;
  width: 100%;
  padding-bottom: 120px;
  background: var(--white);
}

.question-content {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.question-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: var(--white);
  border-top: 1px solid var(--border-color);
  display: grid;
  grid-template-columns: minmax(120px, auto) 1fr minmax(240px, auto);
  align-items: center;
  padding: 0 24px;
  gap: 16px;
  z-index: 1001;
}

/* Question Display Components */
.qd-question-display {
  color: var(--text-primary);
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
}

.qd-question-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.qd-general-instruction {
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-secondary);
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border-color);
}

.qd-question-text {
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-primary);
  margin-bottom: 24px;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: normal;
}

.qd-answer-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.qd-answer-option {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  background: var(--white);
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  box-sizing: border-box;
}

.qd-answer-option:hover:not(:disabled) {
  border-color: var(--primary-purple);
  background: var(--lightest-purple);
}

.qd-answer-option.selected {
  border-color: var(--primary-purple);
  background: var(--lightest-purple);
}

.qd-answer-option.selected .qd-answer-number {
  background: var(--primary-purple);
  color: var(--white);
  border-color: var(--primary-purple);
}

.qd-answer-option.correct {
  border-color: #10B981;
  background: #ECFDF5;
}

.qd-answer-option.correct .qd-answer-number {
  background: #10B981;
  color: var(--white);
  border-color: #10B981;
}

.qd-answer-option.incorrect {
  border-color: #EF4444;
  background: #FEF2F2;
}

.qd-answer-option.incorrect .qd-answer-number {
  background: #EF4444;
  color: var(--white);
  border-color: #EF4444;
}

/* Button Styles */
.nav-btn {
  padding: 8px 24px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn.previous {
  background: var(--white);
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
}

.nav-btn.next {
  background: var(--primary-purple);
  color: var(--white);
  border: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .question-footer {
    height: 64px;
    padding: 0 16px;
    grid-template-columns: auto 1fr auto;
    gap: 12px;
  }

  .report-btn {
    padding: 8px;
  }

  .report-btn .report-text {
    display: none;
  }

  .nav-btn {
    padding: 8px 16px;
    min-width: unset;
    font-size: 13px;
  }

  .nav-btn.previous {
    padding: 8px 12px;
  }

  .nav-btn.next {
    padding: 8px 16px;
  }
  
  .qd-question-display {
    padding: 12px;
  }

  .qd-question-title {
    font-size: 18px;
  }
  
  .qd-general-instruction,
  .qd-question-text {
    font-size: 15px;
    margin-bottom: 16px;
  }
}

/* Small Mobile Styles */
@media (max-width: 375px) {
  .question-footer {
    padding: 0 12px;
    gap: 8px;
  }

  .nav-btn {
    padding: 8px 12px;
  }
  
  .qd-question-display {
    padding: 10px;
  }
}

/* Remove underline from vocabulary and expressions */
.vocabulary-term,
.contextual-expression,
[data-term-type="vocabulary"],
[data-term-type="expression"] {
  text-decoration: none !important;
  border-bottom: none !important;
  font-weight: inherit;
}