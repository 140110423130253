/* Update layout styles to handle scrolling */
html, body {
    min-height: 100%;
    background-color: #FAFAFA;
}

.study-plan-layout {
    display: flex;
    min-height: 100vh;
    background-color: #FAFAFA;
    position: relative;
}

/* Desktop progress bar container */
.desktop-progress-bar-container {
    position: fixed;
    left: 280px;
    top: 0;
    bottom: 0;
    width: 280px;
    background-color: #FAFAFA;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.progress-header {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
}

.progress-header div {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.progress-content {
    padding: 12px;
    flex: 1;
    overflow-y: auto;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.progress-step {
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    color: #666;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.progress-step:hover {
    background-color: #F0F0F0;
}

.progress-step.current {
    background-color: #5932EA;
    color: #ffffff;
    font-weight: 500;
}

/* Main container */
.study-plan-container {
    margin-left: 580px;
    width: calc(100% - 580px);
    padding: 24px 40px;
    min-height: 100vh;
    background-color: #FAFAFA;
}

.study-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 24px;
}

/* Analytics box */
.analytics-box-container {
    margin-bottom: 20px;
}

.analytics-box {
    padding: 12px 18px;
    border: 1.5px solid #3E00EF;
    border-radius: 8px;
    background-color: white;
    color: #3E00EF;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    font-size: 16px;
}

.analytics-box:hover {
    background-color: #f0f2ff;
}

/* Comments section */
.comments-section {
    margin-top: 20px;
    padding: 0 0px;
}

.comments-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.comment-box {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-box p {
    margin: 0;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    padding-right: 30px;
}

.comment-close-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-image: url('../assets/icons/removecomment.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.comment-close-btn:hover {
    opacity: 1;
}

/* Mobile styles */
@media (max-width: 768px) {
    .study-plan-container {
        margin-left: 0;
        width: 100%;
        padding: 0 20px;
    }

    .desktop-progress-bar-container {
        display: none;
    }

    .comments-section {
        padding: 0 15px;
    }
}

/* Add styles for the exam widgets container */
.exams-list-container {
    background-color: #F5F7FF;
    border-radius: 16px;
    padding: 24px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Update exam item styles */
.exam-item {
    width: 100%;
}

.exam-item-box {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.exam-item-box:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.exam-item-completed {
    background-color: white;
    border: 1px solid #E0E0E0;
}

.exam-item-not-completed {
    background-color: white;
    border: 1px solid #E0E0E0;
}

/* Mobile styles */
@media (max-width: 768px) {
    .exams-list-container {
        margin: 20px 0;
        border-radius: 12px;
        padding: 16px;
    }
}

/* Container for all study content */
.study-content-container {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 24px;
    margin-top: 20px;
}

/* Title inside container */
.study-content-container .study-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

/* Analytics box inside container */
.study-content-container .analytics-box-container {
    margin-bottom: 24px;
}

.study-content-container .analytics-box {
    padding: 12px 18px;
    border: 1.5px solid #3E00EF;
    border-radius: 8px;
    background-color: white;
    color: #3E00EF;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    font-size: 16px;
}

.study-content-container .analytics-box:hover {
    background-color: #f0f2ff;
}

/* Exam list styles */
.study-content-container .exams-list-container {
    background-color: transparent;
    padding: 0;
    margin-top: 0;
}

/* Mobile styles */
@media (max-width: 768px) {
    .study-content-container {
        margin: 20px 0;
        border-radius: 12px;
        padding: 16px;
    }
}

.exam-item-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

/* Add these styles to your StudyPlan.css */
.mobile-menu-icon {
    position: fixed;
    top: 20px;
    left: 20px;
    font-size: 25px;
    cursor: pointer;
    z-index: 1001;
    color: #333;
    background-color: white;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .mobile-menu-icon {
        display: block;
    }

    .study-plan-container {
        margin-left: 0;
        width: 100%;
        padding-top: 80px !important;
    }

    .study-content-container {
        margin-top: 0;
    }
}