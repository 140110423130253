.askmina-fullscreen {
    position: fixed;
    bottom: 80px; /* Adjust based on the height of the Ask Mina button and desired spacing */
    right: 0px;
    width: 40%; /* Adjust width as needed */
    height: 80%; /* Adjust height as needed */
    /* background-color: #fff; */
    overflow: auto;
  
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 20px;
}


.chat-container-border {
    border: 2px solid #ddd;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 140px; /* Adjusted to leave some space at the bottom */
    left: 31%;
    height: 70px;
    width: calc(30% - 40px); /* Match the width of the AskMina box, accounting for right padding */
}

.chat-container {
  

    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    
    margin-bottom: 100px; /* Space for the input container */
    height: calc(100vh - 70px); /* Adjust height to leave space for the input box */
}

.chat-message {
    margin: 10px;
    padding: 12px 15px;
    border-radius: 18px;
    max-width: 75%;
    word-wrap: break-word;
}

.chat-message.user {
    background-color: #9334E9;
    align-self: flex-end;
    border-bottom-right-radius: 2px;
}

.chat-message.system {
    background-color: #e8e8e8;
    align-self: flex-start;
    border-bottom-left-radius: 2px;
    color: #333;
}

.input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
}

.input-container input {
    flex-grow: 1;
    height: 50px;
    padding: 0 20px;
    border: none;
    background-color: transparent;
    font-size: 16px;
    outline: none;
}

.input-container button {
    height: 50px;
    padding: 0 20px;
    background-color: #9334E9;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

.input-container button:hover {
    background-color: #7828C8;
}

.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.welcome-message {
    font-weight: bold;
    font-size: 24px;
    color: #333;
    margin-bottom: 150px;
}

.suggestions-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 80%;
    max-width: 600px;
    margin-top: 20px;
}

.suggestion-box {
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.suggestion-box:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.suggestion-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
}

.subtitle {
    font-size: 14px;
    color: #666;
}

/* .ask-mina-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;
    width: 100%;
    height: 100%;
} */

.back-button {

    background: none;
    border: none;
    color: #007aff; /* iOS blue color */
    font-size: 16px;
   
    align-items: right;
    align-content: right;
    padding: 0;
}

.arrow{
    width: 15;
    height: 15;

}
.mina {
    margin-top: 20px;
    width: 100px;
    height: 100px; 
  }
/* Media Query for Phone View */
@media (max-width: 768px) {



    .askmina-fullscreen {
        background-color: #fff; 
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

   .input-container {
    margin-bottom: 0px;
    position: fixed;
    /* bottom: 20px; Adjust this value to control distance from bottom */
    left: 50%;
    transform: translateX(-50%);
    width: 90%; /* Adjust width as needed */
    max-width: 90%; /* Optional: set a maximum width */
    border: 2px solid #ddd;
    border-radius: 50px;
    padding: 5px 0px; /* Add padding to give space inside the container */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff; /* Add a background color if needed */
}

    .chat-container-border {
        border: 0px solid rgb(255, 255, 255);
        top:86%;
       color: transparent;
    
        border-radius: 50px;
    
        width: 0%; /* Match the width of the AskMina box */
        /* box-shadow: 0px -2px 5px rgba(0,0,0,0.1); */
    }
}


.arrow-button {
    flex-shrink: 0;
    height: 50px;
    width: 50px;
    background-color: #9334E9;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.arrow-button:hover {
    background-color: #7828C8;
}

.arrow-image {
    width: 20px;
    height: 20px;
}

.back-icon{
    cursor: pointer; 
    width: 30px;
    height: 30px;
    color: #000;
   align-content: right;

}

.ask-mina-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    justify-content: center; 
    align-items: flex-end;
    z-index: 1000;
}

.askmina-popup {
    position: fixed;
    bottom: 120px;
    left: 30%;
    width: 30%;
    height: 80%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.upper-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: white;
    border-bottom: 1px solid #ddd;
}

.close-button {
    cursor: pointer;
}

.close-icon {
    width: 20px;
    height: 20px;
}

.mina-title {
    font-size: 18px;
    font-weight: bold;
}

.chat-container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
}

.chat-container-border {
    border-top: 1px solid #ddd;
    padding: 15px;
}

.input-container {
    display: flex;
    align-items: center;
}

.input-container input {
    flex-grow: 1;
    height: 50px;
    padding: 0 20px;
    border-radius: 20px;
    margin-right: 10px;
    font-size: 16px;
    outline: none;
}

.arrow-button {
    height: 50px;
    width: 50px;
    background-color: #9334E9;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

.arrow-button:hover {
    background-color: #272499;
}

.arrow-image {
    width: 20px;
    height: 20px;
}
