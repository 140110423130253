.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.page-title {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.exam-section {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}

.exam-section h2 {
  color: #444;
  margin-bottom: 15px;
}

.exam-list {
  list-style-type: none;
  padding: 0;
}

.exam-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* margin-bottom: 10px; */
  transition: all 0.3s ease;
}

.exam-item:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.exam-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: #333;
  text-decoration: none;
}

.exam-id {
  font-weight: bold;
}

.exam-date {
  color: #666;
}

.exam-score {
  background-color: #e6f3ff;
  color: #0066cc;
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
}

.no-exams {
  color: #666;
  font-style: italic;
}

.loader-container, .error-container {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}

.error-container {
  color: #cc0000;
}

.questions-container {
  margin-top: 20px;
}

.question-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.question-item.correct {
  border-left: 5px solid #4CAF50;
}

.question-item.incorrect {
  border-left: 5px solid #F44336;
}

.question-text {
  font-weight: bold;
  margin-bottom: 10px;
}

.choices {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.choice {
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 4px;
}

.choice.selected {
  background-color: #2196F3;
  color: white;
}

.choice.correct {
  background-color: #4CAF50;
  color: white;
}

.choice.selected.correct {
  background-color: #4CAF50;
  color: white;
}

.choice.selected:not(.correct) {
  background-color: #F44336;
  color: white;
}
.users-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.user-item {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.user-item:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transform: translateY(-2px);
}

.user-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: #333;
  text-decoration: none;
}

.username {
  font-weight: bold;
  font-size: 18px;
}

.user-id {
  color: #666;
  font-size: 14px;
}

.no-users {
  text-align: center;
  color: #666;
  font-style: italic;
  margin-top: 20px;
}

.loader-container, .error-container {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}

.error-container {
  color: #cc0000;
}

.page-title {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  padding: 15px;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
  text-align: center;
}

.create-study-plan-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.create-study-plan-btn:hover {
  background-color: #45a049;
}
.exam-item.completed {
  background-color: #e6f7e6;
}

.exam-item.not-completed {
  background-color: #fff0f0;
}

.exam-status {
  font-weight: bold;
  margin-left: 10px;
}

.completed .exam-status {
  color: #4CAF50;
}

.not-completed .exam-status {
  color: #f44336;
}

.create-stage-btn {
  margin-left: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.modal-content input {
  margin-bottom: 10px;
  padding: 5px;
}

.modal-content button {
  margin-top: 10px;
}

.add-comment-btn {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.add-comment-btn:hover {
  background-color: #1976D2;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 15px;
}

.modal-content textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.modal-buttons button {
  margin-left: 10px;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: #4CAF50;
  color: white;
}

.modal-buttons button:last-child {
  background-color: #f44336;
  color: white;
}


.comments-section {
  margin-bottom: 20px;
}

.expandable-header {
  cursor: pointer;
  user-select: none;
}

.comments-content {
  margin-top: 10px;
}

.comment {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.comment p {
  margin: 0 0 5px 0;
}

.comment small {
  color: #666;
}

.search-container {
  margin-bottom: 20px;
  text-align: center;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.3);
}