/* src/shared/styles/features/Dashboard/Dashboard.css */

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.8; }
  100% { opacity: 1; }
}
.dashboard {
  padding: 24px 32px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Recommendation Alert */
.recommendation-alert {
  background: rgba(127, 90, 240, 0.08);
  border-radius: var(--border-radius-lg, 12px);
  padding: 14px 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(127, 90, 240, 0.15);
}

.recommendation-content {
  flex: 1;
}

.recommendation-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-purple);
  display: block;
  margin-bottom: 4px;
}

.recommendation-content p {
  font-size: 14px;
  line-height: 1.4;
  color: var(--text-primary);
  margin: 0;
}

.close-recommendation {
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 20px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 8px;
}

.close-recommendation:hover {
  background: rgba(127, 90, 240, 0.1);
  color: var(--primary-purple);
}

/* Badge elements kept for use elsewhere */
.badge-icon {
  flex-shrink: 0;
}

.label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  display: inline-block;
}

/* Japan Trip Section */
.japan-trip-section {
  background: rgba(127, 90, 240, 0.08);
  border-radius: var(--border-radius-lg);
  padding: 20px;
  color: var(--text-primary);
  min-height: 160px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  position: relative;
}

.japan-trip-section .countdown-badge {
  background: rgba(127, 90, 240, 0.25);
  color: var(--primary-purple);
  font-weight: 600;
}

.japan-trip-section .label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--primary-purple);
  padding-left: 0;
}

.japan-trip-section h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--text-primary);
}

.event-description {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 20px;
  color: var(--text-secondary);
}

.challenge-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 20px;
}

.challenge-box {
  background: rgba(255, 255, 255, 0.6);
  border-radius: var(--border-radius-md, 8px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.challenge-box.completed {
  background: rgba(127, 90, 240, 0.08);
  border-color: rgba(127, 90, 240, 0.2);
}

.challenge-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.challenge-header h3 {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.check-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: transparent;
  flex-shrink: 0;
}

.check-circle.large {
  width: 22px;
  height: 22px;
  font-size: 14px;
}

.challenge-box.completed .check-circle {
  background: var(--primary-purple);
  color: white;
  border-color: var(--primary-purple);
}

/* Lock Status Styling */
.lock-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 4px;
}

.lock-icon {
  color: #a0a0a0;
  stroke-width: 1.5px;
  transition: color 0.2s ease;
}

.unlock-icon {
  color: var(--primary-purple);
  stroke-width: 1.5px;
  filter: drop-shadow(0 1px 2px rgba(127, 90, 240, 0.2));
  transition: all 0.3s ease;
}

.challenge-box.completed .lock-icon,
.challenge-box.completed .unlock-icon {
  color: var(--primary-purple);
}

.challenge-box:hover .lock-icon {
  color: #888;
}

.challenge-box:hover .unlock-icon {
  transform: scale(1.05);
}

.challenge-box p {
  font-size: 13px;
  color: var(--text-secondary);
  line-height: 1.4;
  margin-bottom: 12px;
  flex-grow: 1;
}

.challenge-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.challenge-progress span {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
}

.challenge-action {
  font-size: 12px;
  color: var(--primary-purple);
  text-decoration: none;
  padding: 5px 12px;
  background: rgba(127, 90, 240, 0.1);
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.challenge-action:hover {
  background: rgba(127, 90, 240, 0.2);
  transform: translateY(-1px);
}

.enter-draw-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin: 0 auto;
  background: var(--primary-purple);
  color: white;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  width: fit-content;
  transition: background 0.2s;
}

.enter-draw-btn:hover {
  background: var(--primary-purple-dark, #6a4cc7);
}

/* Media Queries for the Challenge Boxes */
@media (max-width: 768px) {
  .challenge-boxes {
    grid-template-columns: 1fr;
  }
}

/* Section Headers */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.section-header h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
}

.browse-all {
  padding: 6px 12px;
  background: var(--background);
  border: none;
  border-radius: 16px;
  color: var(--text-secondary);
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
}

/* Contests Grid */
.contests-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 24px;
}

.contest-card {
  background: var(--light-purple);
  padding: 16px;
  border-radius: var(--border-radius-lg);
  position: relative;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.contest-card h3 {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 4px;
  line-height: 1.2;
}

.contest-card p {
  color: var(--text-primary);
  font-size: 13px;
  line-height: 1.4;
  margin: 0;
}

.xp-badge {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
}

/* Resources Grid */
.resources-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.resource-card {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: var(--background);
  border-radius: var(--border-radius-lg);
  transition: transform 0.2s, box-shadow 0.2s;
}

.resource-card.purple-card {
  background: rgba(127, 90, 240, 0.08);
  border: 1px solid rgba(127, 90, 240, 0.15);
}

.resource-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
}

.resource-icon {
  width: 20px;
  height: 20px;
  color: var(--primary-purple);
}

.resource-content h3 {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 4px;
}

.resource-content p {
  color: var(--text-secondary);
  font-size: 13px;
  line-height: 1.4;
  margin: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .dashboard {
    padding: 16px;
  }

  .contests-grid {
    grid-template-columns: 1fr;
  }

  .contest-card {
    height: auto;
    min-height: 160px;
  }

  .resources-grid {
    grid-template-columns: 1fr;
  }

  .resource-card {
    width: 100%;
    padding: 16px;
  }

  .japan-trip-card {
    flex-direction: column;
  }

  .trip-image {
    width: 100%;
    height: 150px;
    margin-top: 20px;
  }
}

/* Extra Small Screens */
@media (max-width: 375px) {
  .dashboard {
    padding: 12px;
  }
}

/* Toast Notification */
.toast-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-purple);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1000;
  animation: slideUp 0.3s ease, fadeOut 0.3s ease 2.7s;
  max-width: 90%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Premium Card Styles - Using mauve/purple and white instead of yellow */
.premium-card {
  background: rgba(127, 90, 240, 0.08);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid rgba(127, 90, 240, 0.15);
}

.premium-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #7F5AF0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.premium-content {
  flex: 1;
}

.premium-content h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 4px;
}

.premium-content p {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 16px;
  line-height: 1.4;
}

.premium-button {
  background: #7F5AF0;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;
}

.premium-button:hover {
  background: #6A4DD0;
}