/* mobile-spacing-fix.css */
/* Fixes for mobile spacing - push content down slightly to avoid menu overlap */

/* Add padding-top to all main content containers on mobile */
@media (max-width: 768px) {
  /* General content containers */
  .dashboard,
  .training-view,
  .settings-view,
  .study-plan-view,
  .personalized-page,
  .diagnostic-page,
  .settings-container {
    padding-top: 24px !important; /* Reduced spacing */
  }
  
  /* Dashboard header spacing - touch of margin */
  .dashboard-header,
  .training-header,
  .settings-header {
    margin-top: 8px;
  }
  
  /* Question layout needs just a bit of space at the top */
  .question-layout {
    padding-top: 16px !important; /* Reduced spacing */
  }
  
  /* Sidebar always on top */
  .Sidebar {
    z-index: 2000 !important; /* Keep sidebar above other elements */
  }
  
  /* App main content spacing */
  .App .main-content {
    padding-top: 12px !important; /* Reduced spacing */
  }
  
  /* Let Dashboard.css handle training card positioning */
}

/* Fix for specific components in the trainings view */
@media (max-width: 768px) {
  .session-selector {
    margin-top: 8px; /* Reduced spacing */
  }
  
  /* Adjustments for question layout in mobile */
  .question-container {
    padding-top: 24px !important; /* Reduced spacing */
  }
  
  /* Make mobile menu touch targets larger */
  .mobile-menu-icon {
    padding: 8px; /* Add some padding for easier tapping */
  }
}

/* Desktop spacing adjustments */
@media (min-width: 769px) {
  /* Content containers on desktop */
  .dashboard,
  .training-view,
  .settings-view,
  .study-plan-view,
  .personalized-page,
  .diagnostic-page,
  .settings-container {
    padding-top: 16px !important; /* Reduced desktop spacing */
  }
  
  /* Dashboard header desktop spacing */
  .dashboard-header,
  .training-header,
  .settings-header {
    margin-top: 0;
    margin-bottom: 24px !important; /* Reduced margin bottom */
  }
  
  /* Main content area desktop */
  .main-content {
    padding-top: 16px !important;
  }
  
  /* Session selector desktop */
  .session-selector {
    margin-bottom: 24px !important;
  }
  
}

/* Extra small screen fixes */
@media (max-width: 375px) {
  /* Mobile spacing adjustments */
}