.admin-portal {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-header {
  margin-bottom: 2rem;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 1rem;
}

.admin-header h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.admin-header p {
  color: #666;
}

.admin-section {
  margin-bottom: 2rem;
}

.admin-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  border-left: 4px solid #4f46e5;
  padding-left: 0.75rem;
}

.admin-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.admin-card-content {
  padding: 1.5rem;
}

.admin-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.admin-card p {
  color: #666;
  margin-bottom: 1rem;
}

.exam-list {
  margin-top: 1rem;
}

.exam-list ul {
  list-style: none;
  padding: 0;
}

.exam-item {
  border: 1px solid #eaeaea;
  border-radius: 6px;
  margin-bottom: 0.75rem;
  transition: all 0.2s ease;
}

.exam-item:hover {
  background-color: #f8f9ff;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.exam-link {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-decoration: none;
  color: inherit;
}

.exam-level {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.exam-date {
  font-size: 0.875rem;
  color: #666;
}

.admin-links {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.admin-link-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-link-card:hover {
  background-color: #4f46e5;
  color: white;
  transform: translateY(-3px);
}

.admin-link-card:hover p {
  color: rgba(255, 255, 255, 0.8);
}

.admin-link-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.admin-link-card p {
  color: #666;
  font-size: 0.875rem;
}

.admin-tips {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 2rem;
}

.admin-tips h3 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.admin-tips ul {
  padding-left: 1.5rem;
}

.admin-tips li {
  margin-bottom: 0.5rem;
}

.admin-portal.loading, 
.admin-portal.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #eaeaea;
  border-bottom-color: #4f46e5;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
  margin: 2rem 0;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: #e53e3e;
  margin: 1rem 0;
  padding: 1rem;
  background-color: #fff5f5;
  border-radius: 6px;
  max-width: 500px;
}