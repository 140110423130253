/* TrainingView.css */
.training-view {
  padding: 24px 32px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;
}

.dashboard-header h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

/* Session Selector */
.session-selector {
  margin-bottom: 32px;
  position: relative;
}

.session-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  background: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  min-width: 200px;
}

.session-button:hover {
  border-color: var(--primary-purple);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.arrow-icon {
  color: var(--text-secondary);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: 12px;
}

.arrow-icon.rotated {
  transform: rotate(180deg);
  color: var(--primary-purple);
}

.session-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: var(--white);
  border-radius: var(--border-radius-lg);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 10;
  min-width: 200px;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s ease;
  pointer-events: none;
}

.session-dropdown.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.session-option {
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  border: none;
  background: none;
  font-size: 14px;
  color: var(--text-primary);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.session-option:hover {
  background: var(--background);
}

.session-option.active {
  color: var(--primary-purple);
  background: var(--light-purple);
}

/* Exercises Grid */
.exercises-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.exercise-card {
  min-height: 160px;
  padding: 20px;
  border-radius: var(--border-radius-lg);
  background: var(--white);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.exercise-card:hover, .exercise-card:active {
  border-color: var(--primary-purple);
  transform: translateY(-2px);
}

.exercise-card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
}

.exercise-info {
  margin-top: auto;
}

.exercise-type {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 8px;
}

.exercise-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-primary);
  line-height: 1.4;
  margin: 0;
}

.progress-bar {
  height: 4px;
  background: var(--background);
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
}

.progress-fill {
  height: 100%;
  background: #9334E9; /* Updated to mauve color */
  border-radius: 2px;
  transition: width 0.2s ease;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .training-view {
    padding: 16px;
  }

  .dashboard-header {
    margin-bottom: 24px;
  }

  .dashboard-header h1 {
    font-size: 20px;
  }

  .exercises-grid {
    grid-template-columns: 1fr;
  }

  .exercise-card {
    min-height: 140px;
    padding: 16px;
  }

  .session-button {
    font-size: 14px;
    padding: 10px 16px;
    min-width: 180px;
    width: 100%;
  }

  .session-dropdown {
    min-width: 180px;
    width: 100%;
  }
  
  .arrow-icon {
    width: 14px;
    height: 14px;
  }
}

/* Extra Small Screens */
@media (max-width: 375px) {
  .training-view {
    padding: 12px;
  }
}